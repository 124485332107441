
     <div class="popup mx-auto">
        <div class="barraSuperiorModal">{{'editarAlumnoTxt' | translate}}</div>
        <form class="p-4 p-md-5 bg-light" [formGroup]="formEditarAlumno" autocomplete=off>
			
			<h4>{{surname}}, {{name}}</h4>
			
			<p *ngIf="this.isValida">{{'licenciaValidaHasta' | translate}} {{licennceEnds}}</p>
      <p *ngIf="!this.isValida && licennceEnds">{{'licenciaCaducadaDesde' | translate}} {{licennceEnds}}</p>
      <p *ngIf="!this.isValida && !licennceEnds">{{'noLicencia' | translate}}  {{tituloProyecto}}</p>

      
          <div class="form-floating mb-3">
			 
            <input type="text" class="form-control" id="licenciaNameEditar" placeholder="licenciaNameEditar" formControlName="licenciaNameEditar" [ngClass]="{ 'is-invalid': !licenciaValida }">
            <label for="floatingPassword">{{'licenciaTxt' | translate}}</label><i class="icono fas fa-lock-open"></i>
            <div *ngIf="!licenciaValida" class="invalid-feedback">
              <div *ngIf="!licenciaValida">{{'errorLicenciaIntruducir' | translate}}</div>
            </div>  
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="NombreAlumnoEditar" placeholder="NombreAlumnoEditar" formControlName="NombreAlumnoEditar">
            <label for="floatingPassword">{{'nombreTxt' | translate}}</label><i class="icono fas fa-user"></i>
            <!-- <div class="invalid-feedback" style="display: block;" *ngIf=nonStudentName>Se necesita un nombre.</div> -->
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="ApellidoAlumnoEditar" placeholder="ApellidoAlumnoEditar" formControlName="ApellidoAlumnoEditar">
            <label for="floatingPassword">{{'apellidoTxt' | translate}}</label><i class="icono fas fa-user"></i>
            <!-- <div class="invalid-feedback" style="display: block;" *ngIf=nonStudentSurname>Se necesita un apellido.</div> -->
          </div>
          <div class="form-floating mb-3"> <div class="input-group" style="margin:20px 0;">
                <span class="input-group-text eliminar">
                    <input type="checkbox" class="form-check-input mt-0" (change)="onCheckboxChange()">
                </span>
                <p style="margin: 0;">{{'eliminarAlumno' | translate}}</p><i class="fas icono limpio fa-trash-alt"></i>
			   <div class="invalid-feedback" style="display: block;" *ngIf=showBorrar>{{'eliminarAlumnoWarning' | translate}}</div>
            </div></div>
          <button type="button" class="btn btn-primary btn-md btnAceptar" (click)="createStudent()" *ngIf=!showBorrar>{{'aceptar' | translate}}</button>
			    <button type="button" class="btn btn-danger btn-md btnBorrar" *ngIf=showBorrar (click)="deleteStudent()" >{{'eliminar' | translate}}</button>
          <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="closeCreateClase()">{{'cancelar' | translate}}</button>
          

        </form>
      </div>
