import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import Validation from '../../../utils/Validation';
import { LoginService } from 'src/app/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})


  // type ValidationErrors = {
  //   [key: string]: any;
  // };

export class RegisterComponent implements OnInit {

  @Output() eventRegisterCompleted = new EventEmitter();


  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, 
    private service:LoginService,
    public translate: TranslateService) {

      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
    }

  ngOnInit(): void {

    this.form = this.formBuilder.group(
      {
        name: ['',
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20)],
        surname: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20)
          ]
        ],
        emailProfe: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required]
      },
      {
        validators: [Validation.match('password', 'confirmPassword')] //, this.checkEmail('emailProfe')
      }
    );
  }

  showRegistro = false;

  onCheckboxChange(){
    this.showRegistro = (document.getElementById("registrarCheckbox") as HTMLInputElement).checked;

  }

  onMailProfe(){

    console.log(this.form.value.emailProfe);
    if(this.form.value.emailProfe.length > 2){
      this.service.checkProfe(this.form.value.emailProfe).subscribe(response => {
        console.log(response);
        if(parseInt(response['CODE']) == 1){
          //Todo OK
        }else{
          this.submitted = true;
          this.f.emailProfe.setErrors({ matching: true });
        }
      });
    }

  }

  onBtnRegistrarse(){

    
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    //obtenemos nombre de la clase por defecto
    this.translate.get('nombreClasePorDefecto').subscribe((classNameTranslated: string) => {
      // console.log("TEST: ", classNameTranslated);

      //registro
      this.service.registerProfe(this.form.value.emailProfe, this.form.value.password, this.form.value.name , this.form.value.surname, classNameTranslated).subscribe(response => {

        if(parseInt(response['CODE']) == 1){
          //Todo OK
          this.eventRegisterCompleted.emit();
        }else{
          console.log(response['MESSAGE']);
          this.f.emailProfe.setErrors({ matching: true });
        }
      });
    });
  }

  onBtnClose(){
    // this.loginComponent.onRegisterCompleted();
    this.eventRegisterCompleted.emit();
  }


  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }


}
