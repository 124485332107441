import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-recuperar',
  templateUrl: './recuperar.component.html',
  styleUrls: ['./recuperar.component.css']
})
export class RecuperarComponent implements OnInit {

  @Output() eventRecuperarCompleted = new EventEmitter();


  constructor(private formBuilder: FormBuilder, private service:LoginService, public translate: TranslateService) { 
    translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
  }
  
  formRecuperar: FormGroup;
  submitted = false;
  toogleEstado = false;
  ngOnInit(): void {

    if(localStorage.getItem("idioma")){
      if(localStorage.getItem("idioma")== "ESP"){
        this.translate.setDefaultLang('es-ES');
      }
  
      if(localStorage.getItem("idioma") == "ENG"){
        this.translate.setDefaultLang('en-GB');
      }
  
      if(localStorage.getItem("idioma") == "VAL"){
        this.translate.setDefaultLang('va-ES');
      }

      if(localStorage.getItem("idioma") == "CAT"){
        this.translate.setDefaultLang('ca-ES');
      }

    }else{
      localStorage.setItem("idioma", "ESP");
      this.translate.setDefaultLang('es-ES');

    }

    this.formRecuperar = this.formBuilder.group(
      {
        emailProfe: ['', [Validators.required, Validators.email]]
      }
    );

  }

  onBtnRegistrarse(){

    this.submitted = true;

    if (this.formRecuperar.invalid) {
      return;
    }

    this.service.checkProfe(this.formRecuperar.value.emailProfe).subscribe(response => {
      console.log(response);
      if(parseInt(response['CODE']) == 1){
        this.submitted = true;
        this.f.emailProfe.setErrors({ matching: true });
      }else{
        //TODO: llamar al servicio para que cree una nueva pass para el usuario



        this.toogleEstado = true;
      }
    });


  }


  onReset(): void {
    this.submitted = false;
    this.formRecuperar.reset();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formRecuperar.controls;
  }


  
  onBtnClose(){
    this.toogleEstado = false;
    this.eventRecuperarCompleted.emit();
  }

  onBtnSendNewPass(){
    this.service.newPass(this.formRecuperar.value.emailProfe).subscribe(response => {
      this.toogleEstado = false;
      this.eventRecuperarCompleted.emit();
    });



  }
}
