import { Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { SubBarComponent } from '../sub-bar/sub-bar.component';
import { LoginService } from 'src/app/services/login/login.service';
import { Router, ActivatedRoute } from "@angular/router";
import { reduce } from 'rxjs/operators';
import { FooterComponent } from '../footer/footer.component';
import { TranslateService } from '@ngx-translate/core';
import { getNumberOfCurrencyDigits } from '@angular/common';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})

export class ProgressComponent implements OnInit {
  studenId = "";
  constructor(private service:LoginService, 
    private router: Router,private actRoute: ActivatedRoute, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
      this.studenId = this.actRoute.snapshot.params.id;
    }


  @ViewChild(TopBarComponent,  {static: true}) topBarComponent!: TopBarComponent;
  @ViewChild(SubBarComponent,  {static: true}) subBarComponent!: SubBarComponent;
  @ViewChild(FooterComponent,  {static: true}) footerComponent!: FooterComponent;

  progressGeneral:string = "";
  slotsArray:any;
  categoriesArray: any;
  name:string = "";
  lastConnection:string = "";
  licenciaText:string = "";
  nombreClase:string = "BACK";
  categorieSelectad: any;
  courseSelected = 1;
  licenciaEnd="";
  numFichasToCompleteSlot:number = 10;
  
  //Categorias para Aula Mates.
  //Cada categoria tiene un numero variable de ejercicios para completar un slot
  kCategoria_aula_mates_numeros:string = "com.itbook.aula_matematicas_numeros";
  kCategoria_aula_mates_operaciones:string = "com.itbook.aula_matematicas_operaciones";
  kCategoria_aula_mates_problemas:string = "com.itbook.aula_matematicas_problemas";
  kCategoria_aula_mates_geometria:string = "com.itbook.aula_matematicas_geometria";
  kCategoria_aula_mates_medidas:string = "com.itbook.aula_matematicas_medida";
  kCategoria_aula_mates_estadistica:string = "com.itbook.aula_matematicas_estadistica";
  kCategoria_aula_mates_probabilidad:string = "com.itbook.aula_matematicas_probabilidad";
  
  //diccionario con las categorias de aula mates y las fichas que hay que completar para cada slot de essa categoria
  map_numFichas_por_categoria = new Map<string, number>([
    [this.kCategoria_aula_mates_numeros, 10],
    [this.kCategoria_aula_mates_operaciones, 10],
    [this.kCategoria_aula_mates_problemas, 5],
    [this.kCategoria_aula_mates_geometria, 7],
    [this.kCategoria_aula_mates_medidas, 7],
    [this.kCategoria_aula_mates_estadistica, 7],
    [this.kCategoria_aula_mates_probabilidad, 7]
  ]);

  ngOnInit(): void {

    if(localStorage.getItem("idioma")){
      if(localStorage.getItem("idioma")== "ESP"){
        this.translate.setDefaultLang('es-ES');
      }
  
      if(localStorage.getItem("idioma") == "ENG"){
        this.translate.setDefaultLang('en-GB');
      }
  
      if(localStorage.getItem("idioma") == "VAL"){
        this.translate.setDefaultLang('va-ES');
      }

      if(localStorage.getItem("idioma") == "CAT"){
        this.translate.setDefaultLang('ca-ES');
      }

    }else{
      localStorage.setItem("idioma", "ESP");
      this.translate.setDefaultLang('es-ES');

    }

    
    this.courseSelected = 1;
    this.getStudentData(1, "default");
    this.nombreClase = localStorage.getItem("clase") as string;

  }

  getStudentData(couse:number, categorie:string){
    
    this.service.getStudentData(this.studenId, couse, categorie).subscribe(response => {

      if(parseInt(response['CODE']) == 1){
  
        this.categoriesArray = response['CATEGORIES'];
        this.categorieSelectad = response['SELECTEDCATEGORIE'];
        //console.log("Cat Selected: " + this.categorieSelectad.categorieId);

        if(!this.categorieSelectad.categorieId){
          //console.log("ha devuielto algo malo");
          this.categoriesArray.forEach(catDevuelta => {
            if(catDevuelta.categorieId == response['SELECTEDCATEGORIE']){
              this.categorieSelectad = catDevuelta;
            }
          });
        }

        this.name = response['STUDENT']['name'] + " "+response['STUDENT']['surname'];

        var d = new Date(response['STUDENT']['updatedAt']);
        this.lastConnection = d.toLocaleDateString(this.translate.getDefaultLang(), { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric'}).toString();


        this.licenciaText = response['LICENCE']['licenceId'];
        this.licenciaEnd = response['LICENCE']['endDate'].split('T')[0];
        
        this.slotsArray = response['PROGRESS'];


        var stars = 0;
        var slots = 0;
        this.slotsArray.forEach(element => {
          stars = stars + element.stars;
          slots++
          //console.log("Slot info: " + element.slotId);
        });

        //console.log("stars: " + stars + ", slots: " + slots);

        var proyect = localStorage.getItem("proyect");
        var progressLocal = 0;
        
        if(proyect == "com.itbook.unmondeparaules")
        {
          progressLocal = parseInt(((stars * 100) / (30)).toString());
        }
        else
        {
          //progressLocal = parseInt(((stars * 100) / (slots*10)).toString());
          progressLocal = parseInt(((stars * 100) / (slots*this.getNumFichasToCompleteForCurrentCategorie())).toString());
        }
         
        this.progressGeneral = (slots <= 0)? "0%":(progressLocal >= 100)? "100%":(progressLocal+"%");
      }
      else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  getNumFichasToCompleteForCurrentCategorie():number
  {
    
    let numFichas:number = 10;

    if(this.isMates())
    {
      if(this.map_numFichas_por_categoria.has(this.categorieSelectad.categorieId))
      {
        if(this.map_numFichas_por_categoria.get(this.categorieSelectad.categorieId) !== undefined)
        {
          numFichas = this.map_numFichas_por_categoria.get(this.categorieSelectad.categorieId) as number;
        }
      }
    }

    //console.log("Numero de fichas para completar slot en esta categoria: " + numFichas);
    return numFichas;
  }
  coursePressed(course:number){
    this.courseSelected= course;
    this.getStudentData(course, this.categorieSelectad.categorieId);

  }

  categoriePressed(categorie:any){
    this.categorieSelectad = categorie;
    this.getStudentData(this.courseSelected, categorie.categorieId);

  }

  getwidth(stars){

    var proyect = localStorage.getItem("proyect");
    var returnValue = stars * 10;
    
    if(proyect == "com.itbook.unmondeparaules")
      returnValue = Math.ceil(stars*33.333);
    
    if(this.isMates())
    {
      //console.log("Obteniendo progreso para mates...");
      returnValue = (stars * 100) / this.getNumFichasToCompleteForCurrentCategorie();
    }

    if(returnValue >= 100) //con esto nos aseguramos los fallos que ha habido que se ingresan mas estrellas de las posibles
        returnValue = 100;
    
    //console.log("Progreso: " + returnValue);
    return returnValue.toFixed(0)+"%";
  }

  getColor(stars){
    // if(stars <= 3){
    //   return "#dc3545";
    // }
    // if(stars <= 6){
    //   return "#ffc107";
    // }

    return "#28a745";
  }


  onBtnRobot(){
    this.router.navigate(['/robot/'+this.studenId]);
  }


  onCheckboxChangeGeneral(){
    this.service.addRefuerzo(this.studenId, this.courseSelected, this.categorieSelectad, null).subscribe(response => {
      if(parseInt(response['CODE']) == 1){
        this.getStudentData(this.courseSelected, this.categorieSelectad.categorieId);
      }
    });
  }

  onCheckboxChangeSpec(event){
    // console.log("onCheckboxChangeSpec " + event);
    this.service.addRefuerzo(this.studenId, this.courseSelected, this.categorieSelectad, event).subscribe(response => {
      if(parseInt(response['CODE']) == 1){
        this.getStudentData(this.courseSelected, this.categorieSelectad.categorieId);
      }
    });
  }

  isUMP(){
    var proyect = localStorage.getItem("proyect");
    if(proyect == "com.itbook.unmondeparaules")
      return true;
    else
      return false;
  }

  isMates(){
    var proyect = localStorage.getItem("proyect");
    if(proyect == "com.itbook.aula_matematicas")
      return true;
    else
      return false;
  }

  selectedCat(object){
    switch(this.translate.getDefaultLang()) {
      case 'es-ES':
        return object.name;
      break;
      case 'ca-ES':
        return object.nameVAL;
      break;
      case 'va-ES':
        return object.nameVAL;
      break;
      case 'en-GB':
        return object.nameENG;
        break;

    }

    return "Error de traduccion";
  }
}
