import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn } from '@angular/forms';
import { Router } from "@angular/router";
import Validation from 'src/app/utils/Validation';
import { LoginService } from 'src/app/services/login/login.service';
import { CrearLicenciasComponent } from './crear-licencias/crear-licencias.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  @ViewChild(CrearLicenciasComponent,  {static: true}) crearLicenciasComponent!: CrearLicenciasComponent;

  constructor(private router: Router, private formBuilder: FormBuilder,  private service:LoginService, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
    }

  formActualizarProfe: FormGroup;
  submitted = false;

  nombreProfesor ="";

  nombre = "";
  apellido = "";
  email = "";
  role = false;

  languaje = "ESP";
  selectedLanguajeImg = "../../../../assets/login/banderita-esp.png";

  ngOnInit(): void {
    
    if( parseInt(localStorage.getItem("role")) == 0)
      this.role = true;

    this.formActualizarProfe = this.formBuilder.group(
      {
        name: ['',
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20)],
        surname: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20)
          ]
        ],
        emailProfe: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required]
      },
      {
        validators: [Validation.match('password', 'confirmPassword')] //, this.checkEmail('emailProfe')
      }
    );



    this.nombreProfesor = localStorage.getItem("userName");
    this.formActualizarProfe.value.name = this.nombre = localStorage.getItem("firstName");
    this.formActualizarProfe.value.surname = this.apellido = localStorage.getItem("lastName");
    this.formActualizarProfe.value.emailProfe = this.email = localStorage.getItem("email");

    this.formActualizarProfe.controls['emailProfe'].setValue(localStorage.getItem("email"));

      
    if(localStorage.getItem("idioma")){
      this.languaje = localStorage.getItem("idioma");

      if(this.languaje == "ESP"){
        this.translate.setDefaultLang('es-ES');
      }
  
      if(this.languaje == "ENG"){
        this.translate.setDefaultLang('en-GB');
      }
  
      if(this.languaje == "VAL"){
        this.translate.setDefaultLang('va-ES');
      }

      if(this.languaje == "CAT"){
        this.translate.setDefaultLang('ca-ES');
      }

    }else{
      this.languaje = "ESP";
    }
  
      this.selectedLanguajeImgFunc();

  }


  selectedLanguajeImgFunc(){
    if(this.languaje == "ESP"){
      this.selectedLanguajeImg =  "../../../../assets/login/banderita-esp.png";
    }

    if(this.languaje == "ENG"){
      this.selectedLanguajeImg = "../../../../assets/login/banderita-gb.png";
    }

    if(this.languaje == "VAL"){
      this.selectedLanguajeImg = "../../../../assets/login/banderita-vlc.png";
    }

    if(this.languaje == "CAT"){
      this.selectedLanguajeImg = "../../../../assets/login/banderita-cat.png";
    }
  }

  onBtnLogOutClicked(){
    localStorage.clear();
    this.router.navigate(['login']);

  }

  btnEditarProfe(){

    var nombreAux = this.formActualizarProfe.value.name ? this.formActualizarProfe.value.name : this.nombre;
    var apellidoAux = this.formActualizarProfe.value.surname? this.formActualizarProfe.value.surname : this.apellido;
    var emailAux = this.formActualizarProfe.value.emailProfe? this.formActualizarProfe.value.emailProfe : this.email;
    var passAux = this.formActualizarProfe.value.password? this.formActualizarProfe.value.password : "";
    var compPassAux = this.formActualizarProfe.value.confirmPassword? this.formActualizarProfe.value.confirmPassword : "";

    console.log(this.formActualizarProfe.value);



    if (nombreAux.length < 2 || apellidoAux.length < 2 || emailAux.length < 2) {
      this.submitted = true;
      return;
    }


    if(passAux.length > 0){
      this.submitted = true;
      if(passAux.length < 6){
        this.f.password.setErrors({ minlength: true });
        return;
      }else{
        if(passAux !== compPassAux){
          this.f.confirmPassword.setErrors({ matching: true });
          return;
        }
      }
    }


      if(emailAux !== this.email){
        //se ha modificado el email, vamos a ver que pasa
        this.service.checkProfe(emailAux).subscribe(response => {
          console.log(response);
          if(parseInt(response['CODE']) != 1){
            this.submitted = true;
            this.f.emailProfe.setErrors({ matching: true });
  
          }else{
            //TODO: llamar al servicio para que modifique una nueva pass para el usuario
            console.log("emailAux: " + emailAux + ", compPassAux: " + compPassAux + ", nombreAux: " + nombreAux + ", apellidoAux: " + apellidoAux);

            this.service.updateProfe(this.email, compPassAux, nombreAux, apellidoAux, emailAux).subscribe(response => {
              console.log(response);
  
              localStorage.setItem("firstName", nombreAux);
              localStorage.setItem("lastName", apellidoAux);
              localStorage.setItem("email", emailAux);
              localStorage.setItem("userName", nombreAux + " " +apellidoAux);
  
              this.nombreProfesor = localStorage.getItem("userName");
  
              this.showEditar = false;
              this.onReset();
              
            });
          }
        });

      }else{
        //TODO: Modificamos nombre, apellidos y chequeamos el pass
        console.log("emailAux: " + emailAux + ", compPassAux: " + compPassAux + ", nombreAux: " + nombreAux + ", apellidoAux: " + apellidoAux);

          this.service.updateProfe(emailAux, compPassAux, nombreAux, apellidoAux, "").subscribe(response => {
            console.log(response);

            localStorage.setItem("firstName", nombreAux);
            localStorage.setItem("lastName", apellidoAux);
            localStorage.setItem("email", emailAux);
            localStorage.setItem("userName", nombreAux + " " +apellidoAux);

            this.nombreProfesor = localStorage.getItem("userName");

            this.showEditar = false;
            this.onReset();
            
          });
      }
 


  }


  onReset(): void {
    this.submitted = false;
    this.formActualizarProfe.reset();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formActualizarProfe.controls;
  }




  onBtnVLC(){
    localStorage.setItem("idioma", "VAL");
    window.location.reload();
  }

  onBtnESP(){
    localStorage.setItem("idioma", "ESP");
    window.location.reload();
  }

  onBtnENG(){
    localStorage.setItem("idioma", "ENG");
    window.location.reload();
  }

  onBtnCAT(){
    localStorage.setItem("idioma", "CAT");
    window.location.reload();
  }


  showEditar = false;

  btnShowEditar(){
    // this.formActualizarProfe.patchValue({name: "nombre"});
    console.log(this.formActualizarProfe.value);

    this.formActualizarProfe.value.name = this.nombre = localStorage.getItem("firstName");
    this.formActualizarProfe.value.surname = this.apellido = localStorage.getItem("lastName");
    this.formActualizarProfe.value.emailProfe = this.email = localStorage.getItem("email");

    this.formActualizarProfe.controls['emailProfe'].setValue(localStorage.getItem("email"));


    this.showEditar = true;

  }

  showCrearLicencia = false;

  btnShowCrear(){
    this.showCrearLicencia = true;
  }

  eventCerrarLicenciasCompleted(){
    this.showCrearLicencia = false;
  }

  eventCrearLicenciasCloseCompleted(){
//Aquí eventCrearLicenciasCloseCompleted llamamos api para crear licencias
    this.showCrearLicencia = false;
  }


  btnHideEditar(){
    this.onReset();
    this.showEditar = false;
    this.showCrearLicencia = false;

  }

}
