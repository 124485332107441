import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';


export interface IUserInfo{
  user:{
    firstName:string, 
    lastName:string, 
    userId:string, 
    email:string, 
    password:string, 
    lastStudentId:number, 
    token:string, 
    tokenDate:string,
    isValidated:boolean, 
    role:string
  }
}

export interface ILoginInfo{
  email:string,
  password:string
}

@Injectable({
  providedIn: 'root'
})


export class LoginService {
  private urlService = "https://api.aulaitbook.com";//"http://localhost:3000";//
  private urlLoginApi = "/users/login";
  private urlCheckUserApi = "/users/checkUser";
  private urlRegisterApi = "/users/register";
  private urlUpdateProfeApi = "/users/updateUser"
  private urlGetAlumnosApi = "/users/getStudents";
  private urlProyectApi = "/proyect";
  private urlClassroomsApi = "/classrooms";
  private createClassApi= "/classrooms/createClassroom";
  private modifyClassApi = "/classrooms/modifyClassroom";
  private deleteClassApi = "/classrooms/deleteClassroom";
  private createStudentApi = "/licences/createStudent";
  private getStudentProgress = "/users/studentProgress";
  private studentRobotApi = "/lengua/studentRobot";

  private studentRefuerzoApi = "/users/addRefuerzo";
  private createLicenciaApi = "/licences/createLicences";
  private studentDeleteApi = "/licences/deleteStudent";
  private studentModifyApi = "/licences/modifyStudent";
  private studentGetSlotNameApi = "/users/getSlotsNames";
  private urlNewPassApi = "/users/newPass";


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  };

  constructor(private httpClient: HttpClient) { }


  studentGetSlotName(slots: string, languaje: string){
    const payload = new HttpParams()
    .set('project', localStorage.getItem("proyect"))
    .set('languaje', languaje)
    .set('slots', slots);


    return this.httpClient.post<IUserInfo>(this.urlService+this.studentGetSlotNameApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
    
  }

  modifyStudent(licence:string, studentName: string, studentSurame: string, studentId:string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('licence', licence)
    .set('name', studentName)
    .set('surname', studentSurame)
    .set('project', localStorage.getItem("proyect"))
    .set('studentId', studentId);


    return this.httpClient.post<IUserInfo>(this.urlService+this.studentModifyApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );

  }

  deleteStudent(studentId: string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('studentId', studentId);


    return this.httpClient.post<IUserInfo>(this.urlService+this.studentDeleteApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
    
  }

  crearLicencias(proyecto: string, numeroLicencias:number, email: string){
    const payload = new HttpParams()
    .set('email', email)
    .set('numLicencias', numeroLicencias.toString())
    .set('permisos', proyecto);

    return this.httpClient.post<IUserInfo>(this.urlService+this.createLicenciaApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  addRefuerzo(studentId: string, course:number, categorie:string, slotId: string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('course', course.toString())
    .set('categorie', categorie)
    .set('slotId', slotId)
    .set('proyectId', localStorage.getItem("proyect"))
    .set('studentId', studentId);


    return this.httpClient.post<IUserInfo>(this.urlService+this.studentRefuerzoApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
    
  }

  

  registerProfe(email: string, password: string, name: string, surname: string, className: string){
    const payload = new HttpParams()
    .set('email', email)
    .set('password', password)
    .set('name', name)
    .set('lastName', surname)
    .set('className', className);

    return this.httpClient.post<IUserInfo>(this.urlService+this.urlRegisterApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }


  checkProfe(email: string){
    const payload = new HttpParams()
    .set('email', email);

    return this.httpClient.post<IUserInfo>(this.urlService+this.urlCheckUserApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  updateProfe(email: string, password: string, name: string, surname: string, newEmail: string){

    // localStorage.setItem("token", response['USER']['token']);

    const payload = new HttpParams()
    .set('email', email)
    .set('password', password)
    .set('name', name)
    .set('newEmail', newEmail)
    .set('lastName', surname);

    return this.httpClient.post<IUserInfo>(this.urlService+this.urlUpdateProfeApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }


  login(_username:string, _password:string):Observable<IUserInfo>{
    
    
    // const loginInfo:ILoginInfo = {email:_username, password:_password}

    const payload = new HttpParams()
    .set('email', _username)
    .set('password', _password);

    return this.httpClient.post<IUserInfo>(this.urlService+this.urlLoginApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  newPass(_email:string):Observable<IUserInfo>{
    
    
    // const loginInfo:ILoginInfo = {email:_username, password:_password}

    const payload = new HttpParams()
    .set('email', _email);

    return this.httpClient.post<IUserInfo>(this.urlService+this.urlNewPassApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }


  getProyects(){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"));


    return this.httpClient.post<IUserInfo>(this.urlService+this.urlProyectApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getClases(){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"));


    return this.httpClient.post<IUserInfo>(this.urlService+this.urlClassroomsApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
    
  }

  getAlumnos(classroomId: string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('classroomId', classroomId)
    .set('proyectId', localStorage.getItem("proyect"));

    return this.httpClient.post<IUserInfo>(this.urlService+this.urlGetAlumnosApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  createClass(className: string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('name', className).set('surname', "");


    return this.httpClient.post<IUserInfo>(this.urlService+this.createClassApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  modifyClass(className: string, classroomId:string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('name', className)
    .set('surname', "")
    .set('classroomId', classroomId);

    return this.httpClient.post<IUserInfo>(this.urlService+this.modifyClassApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  deleteClass(classroomId:string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('classroomId', classroomId);


    return this.httpClient.post<IUserInfo>(this.urlService+this.deleteClassApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  createStudent(licence:string, studentName: string, studentSurame: string, classroomId:string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('licence', licence)
    .set('name', studentName)
    .set('surname', studentSurame)
    .set('classroomId', classroomId);


    return this.httpClient.post<IUserInfo>(this.urlService+this.createStudentApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );

  }

  getStudentData(studentId: string, course:number, categorie:string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('studentId', studentId)
    .set('categorie', categorie)
    .set('course', course.toString())
    .set('languaje', localStorage.getItem("idioma"))
    .set('proyectId', localStorage.getItem("proyect"));

    return this.httpClient.post<IUserInfo>(this.urlService+this.getStudentProgress, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getStudentRobot(studentId: string){
    const payload = new HttpParams()
    .set('token', localStorage.getItem("token"))
    .set('userId', localStorage.getItem("userId"))
    .set('studentId', studentId)
    .set('proyectId', localStorage.getItem("proyect"));



    return this.httpClient.post<IUserInfo>(this.urlService+this.studentRobotApi, payload, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }



  


  private handleError(error: HttpErrorResponse){
    
    if(error.error instanceof ErrorEvent){
      console.error("Ha ocurrido un error: ", error.error.message);
    }
    else{ //Codigo de error desde el backend
      console.error("Error desde el backend!!!");
      console.error("Codigo: ", error.status);
      console.error("Descripcion: ", error.error);
    }

    return throwError("Ha ocurrido un error en el acceso al servidor.");
  }  
  
}