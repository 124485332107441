<div class="popup mx-auto" >
    <div class="barraSuperiorModal">{{'agregarClaseTitle' | translate}}</div>
    <form class="p-4 p-md-5 bg-light" [formGroup]="formEditarClase">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingClasSname" placeholder="Nom" formControlName="nameClass" [ngClass]="{ 'is-invalid': submitted && mofifyF.nameClass.errors }">
        <label for="floatingPassword">{{'nombreDeLaClase' | translate}}</label><i class="fas fa-chalkboard-teacher icono"></i>
        <div *ngIf="submitted && mofifyF.nameClass.errors" class="invalid-feedback">
            <div *ngIf="mofifyF.nameClass.errors.required">{{'nombreDeLaClaseError' | translate}}</div>
        </div>
      </div>
      <div class="form-floating mb-3">&nbsp;</div>
      <button type="button" class="btn btn-primary btn-md btnAceptar" (click)="createClase()">{{'aceptar' | translate}}</button>
      <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="closeCreateClase()">{{'cancelar' | translate}}</button>
      

    </form>
  </div>