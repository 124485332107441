<main class="fondo">
	
    <app-top-bar></app-top-bar>
    <app-sub-bar></app-sub-bar>
   <div class="container studenInfo">
	
   <div class=" row">
	   <div class="col-12">
		   <p class="bread"><a href="/clases"><i class="fas fa-chevron-left"></i>{{nombreClase}}</a></p>
		   <h2>{{name}}</h2></div>
		<div class="col-md-6 col-12">
		 <p class="ultimaConexio">{{'ultimaConexioTxt' | translate}} <strong>{{lastConnection}}</strong></p>
        
         </div>
   <div class="col-md-6 col-12">
     <p class="userNameField"><span>{{'licenciaTxt' | translate}}  {{licenciaText}} {{'licenciaValidaTxt' | translate}} {{licenciaEnd}}</span></p>
      
       
     
	</div>
	</div>
	</div>
	<div class="container-fluid robot">
		<div class="container">
     <div class="row robotInfo">
		 <div class="col-lg-3 col-md-4 col-12">
			 <div class="botonera">
			<div class="botones">
			<button class="btn_metricas fila_alumno "  (click)="onBtnProgreso()">{{'progresoTxt' | translate}}</button>
				<button class="btn_metricas fila_alumno activo" >{{'robotTxt' | translate}}</button></div></div>
    </div>
	
    <div class="puntuacionGeneral col-lg-2 col-md-2 col-12"><img src="../../../assets/robot/01_robot_web.png" class="imgRobot"></div>
    <div class="progress progressGeneral sombra col-lg-5 col-md-3 col-12">
        <div class="progress-bar barGeneral sombra" role="progressbar" [style.width]=progressGeneral aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
		  <div class="col-lg-2 col-md-3 col-12 puntuacion">
		 
		 <p class="numPuntos">{{points}}</p><br><p class="txtNumPuntos">{{'puntuacionActualTxt' | translate}}</p>
		 </div></div></div>
	</div>
<div class="container tabsCOntainer">
	
	
	
    <div class="reducedBoard"><h5>{{'ultimasSesionesTxt' | translate}}</h5>
      <!-- tabla aciertos/fallos -->
		
		<div class="row aciertos">
		
		<div class="col-xl-3 col-lg-4 col-md-2 col-12"><p class="encertsErradesTxt">{{'aciertosTxt' | translate}}</p></div>
		<div class="col-xl-8 col-lg-7 col-md-9 col-10"> <div class="progress sombra">
                    <div class="progress-bar bg-success  sombra" role="progressbar" [style.width]=aciertos aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div></div>
			<div class="col-xl-1 col-lg-1 col-md-1 col-2"><p class="textProgress">{{aciertos}}</p></div>
		</div>
			<div class="row fallos">
			<div class="col-xl-3 col-lg-4 col-md-2 col-12"><p class="encertsErradesTxt">{{'erroresTxt' | translate}}</p></div>
		<div class="col-xl-8 col-lg-7 col-md-9 col-10"> <div class="progress sombra">
                    <div class="progress-bar bg-danger sombra" role="progressbar" [style.width]=errores aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  
                  </div></div>
			<div class="col-xl-1 col-lg-1 col-md-1 col-2"><p class="textProgress">{{errores}}</p></div>
			
			
		</div>
		<!-- tabla recomendados/fallados -->
		<div class="errades">
			<h5 style="display: flex;">{{'ejercicosMasFallosTxt' | translate}}
				<button class="btn btn-secondary dropdown-toggle" (click)="desplegarErrores()" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin-left: auto;padding-top: 0px;padding-bottom: 0px;background-color: #00C7D1;border: 0px;">
					<span class="refor" style="line-height: 1;">{{listadoErroresNumero}}</span>
				  
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" [style.display]="erroresMenu">
					<a class="dropdown-item" (click)="mostrarErrores(3)">3</a>
					<a class="dropdown-item" (click)="mostrarErrores(5)">5</a>
					<a class="dropdown-item" (click)="mostrarErrores(10)">10</a>
					<a class="dropdown-item" (click)="mostrarErrores(999)">Todos</a>
				  </div>
				</button>
			</h5>

		<!-- </div> -->
			<div class="exercicierrades" *ngFor="let fallos of arrayCalculoRankingHTMLHelp">
			<p class="numeroerrades">{{fallos.numError}}</p>
			<p class="textRecomendados tipeserrades"><strong>{{fallos.categoria}}. {{fallos.name}}</strong><br/><span class="refor"><h6 class="warning">{{'aReforzar' | translate}}</h6> {{fallos.categoria}}. {{fallos.name}}</span> </p>
			<div style="clear: both;"></div>
			</div>
		</div>
	
      
 

            <!-- tabla aciertos/fallos -->
		
		<h5>{{'numeroAciertosSesionTxt' | translate}}</h5>
		<div class="row listado" *ngFor="let session of sessionsArray">
			<div class="col-xl-3 col-lg-4 col-md-5 col-12"><p class="textRecomendados">{{returnDate(session.createdAt)}}</p>
			</div>
			<div class="col-xl-7 col-lg-6 col-md-5 col-9">
				<div class="progress sombra">
					<div class="progress-bar sombra" role="progressbar" [style.width]=getwidth(session.success) [style.background-color]=getColor(session.success) aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">

					</div>
				</div>
			</div>
				
			<div class="col-xl-1 col-lg-1 col-md-1 col-2"><p class="textProgress">{{session.success}}/20</p></div>
			<div class="col-xl-1 col-lg-1 col-md-1 col-1"><i class="fas fa-info-circle"  (click)="hoverScore(session)" style="color: #01c7d1;margin-left: 20px;cursor: pointer;" [style.visibility]="session.success >= 20? 'hidden': 'visible'"></i></div>

		</div>
		
   
     </div>
	</div>
    </main>



	<div id="modalCrearClase" class="modal" *ngIf="toogleEstado">

		<div class="popup mx-auto">
		  <div class="barraSuperiorModal">{{'erroresTxt' | translate}}</div>

		  <div class="p-4 p-md-5 bg-light">
			<div style="margin-bottom: 5px;"><strong>{{fechaFlotante}}</strong></div>
			  <ul class="list-group">
				<li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let fallo of listaFallos">
				  {{fallo.name}}
				  <span class="badge badge-primary badge-pill" style="background-color: #dc3545;">{{fallo.numError}}</span>
				</li>
			  </ul>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="ocultar()">{{'aceptar' | translate}}</button> 
			</div>
		  </div>
		</div>
	</div>
		

	<app-footer></app-footer>
