
<div id="modalCrearClase" class="modal">

    <div class="popup mx-auto">
      <div class="barraSuperiorModal">{{'recuperarContrasenya' | translate}}</div>
      <form class="p-4 p-md-5 bg-light" [formGroup]="formRecuperar" autocomplete=off *ngIf="!toogleEstado">
		  <p>{{'recuperarContrasenyaTxt1' | translate}}</p>
       
          
          <div class="form-floating mb-3">
          <input type="text" class="form-control" id="MailAlumno" placeholder="MailAlumno" formControlName="emailProfe"  [ngClass]="{ 'is-invalid': submitted && f.emailProfe.errors }" required>
          <label for="floatingPassword">Email</label><i class="icono fas fa-envelope"></i>
       <!-- <div class="warning_text"><p class="errorMsg" id="errorMsg">Texto de alerta . Aquest correu no està registrat.</p></div> -->
          <div *ngIf="submitted && f.emailProfe.errors" class="invalid-feedback">
            <div *ngIf="f.emailProfe.errors.required">{{'errorEmailNecesario' | translate}}</div>
            <div *ngIf="f.emailProfe.errors.email">{{'errorEmailValido' | translate}}</div>
            <div *ngIf="f.emailProfe.errors.matching">{{'errorEmailNOExiste' | translate}}</div>
          </div>
        </div>
          
          
      <button type="button" class="btn btn-primary btn-md btnAceptar" (click)="onBtnSendNewPass()">{{'enviar' | translate}}</button>
      <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="onBtnClose()">{{'cancelar' | translate}}</button> 
        

          
          
      </form>

      <div class="p-4 p-md-5 bg-light" *ngIf="toogleEstado">
        <p><strong>{{'newPass' | translate}}</strong>{{'newPass2'| translate}}</p>
              
        <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="onBtnClose()">{{'aceptar' | translate}}</button> 
   
        </div>
      </div>

    </div>
	
<!-- 	
	<div class="popup mx-auto">
      <div class="barraSuperiorModal">RECUPERAR CONTRASENYA</div>
      <div class="p-4 p-md-5 bg-light">
		  <p><strong>T'hem enviat la teua nova contrasenya al teu correu electrònic.</strong> Accedeix amb ella al teu compte i canvia-la a "Editar compte"</p>
            
      <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="onBtnClose()">OK</button> 
 
      </div>
    </div>
	
	

</div> -->

