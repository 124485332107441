<div class="popup mx-auto">
		 
    <div class="barraSuperiorModal">{{'editarClaseTxt' | translate}}</div>
    <form class="p-4 p-md-5 bg-light" [formGroup]="formEditarClase" autocomplete=off>    
    <div class="nameClassFlotante"><h3>{{nombreClase}}</h3></div>
    <div class="numClassFlotante">{{'alumnosEnClase' | translate}} {{alumnos.length}}</div>
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="floatingClasSnameModify" placeholder="Nom" formControlName="nameClass" [ngClass]="{ 'is-invalid': submitted && mofifyF.nameClass.errors }">
        <label for="floatingPassword">{{'nombreDeLaClase' | translate}}</label><i class="fas fa-chalkboard-teacher icono"></i>
        <div *ngIf="submitted && mofifyF.nameClass.errors" class="invalid-feedback">
          <div *ngIf="mofifyF.nameClass.errors.required">{{'nombreDeLaClaseError' | translate}}</div>
        </div>
      </div>
        
        
        
            <div class="form-floating mb-3" *ngIf=!alumnos.length> <div class="input-group" style="margin:20px 0;">
            <span class="input-group-text eliminar">
                <input id="borrarCheckbox" type="checkbox" class="form-check-input mt-0" (change)="onCheckboxChange()">
            </span>
            <p style="margin: 0;">{{'eliminarClaseTxt' | translate}}</p><i class="fas icono limpio fa-trash-alt"></i>
          
        </div>
      </div>
        
        
        
      <div class="invalid-feedback" style="display: block;" *ngIf=alumnos.length>{{'eliminarClaseWarning' | translate}}</div>
      <div class="form-floating mb-3">&nbsp;</div>
      <button type="button" class="btn btn-primary btn-md btnAceptar" *ngIf=!showBorrar (click)="modifyClase()">{{'aceptar' | translate}}</button>
      <button type="button" class="btn btn-danger btn-md btnBorrar" *ngIf=!alumnos.length&&showBorrar (click)="deleteClase()" >{{'eliminar' | translate}}</button>
      <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="closeCreateClase()">{{'cancelar' | translate}}</button>
      
    </form>
  </div>
