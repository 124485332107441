<main class="fondo" >
    <app-top-bar></app-top-bar>
    <app-sub-bar></app-sub-bar>
    <div class="container caja-superior tabsCOntainer">
		<div class="row botonera">
			
			<div class="col-md-8 col-12"><h2><button class="noboton" (click)="openModifyClass()"><i class="far fa-edit" ></i></button> {{nombreClase}}</h2></div>
		<div class="col-md-4 col-12 botones-dcha">
		
		 <div class="dropdown">
                    <a class="btn btn-primary btn-lg dropdown-toggle btnNombre" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      {{'cambiarClase' | translate}}
                    </a>
                  
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" >
						
                      <li *ngFor="let clase of clases">
                          <a class="dropdown-item" (click)="classClicked(clase.classroomId)">{{clase.name}}</a>
                    </li>
                    <li><div class="dropdown-divider"></div></li>
                      <li><a class="dropdown-item" (click)="openModalCreateClase()">{{'crearClase' | translate}}</a></li>
                    </ul>
                   
                  </div>
		
		</div>
		
		
		
		</div>
		
		
		
		
    <div class="row ocultar">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 cabecera-tabla">{{'nombresApellido' | translate}}</div>
      <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 cabecera-tabla">{{'codigoConexion' | translate}}</div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 cabecera-tabla"></div>

      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 cabecera-tabla puntrobot"><p *ngIf="!isUMP() || isMates()">{{'aReforzar' | translate}}</p></div>
      <div class="col-xl-2 col-lg-2 col-md-1 col-sm-6 col-6 cabecera-tabla puntrobot"><p></p></div>
      
  </div>
  <div id="print-section" style = "@page {
    size: A4 landscape;
  }">
    <div class="row alumno" *ngFor="let alumno of alumnos">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 fila-tabla nombre"> <button class="noboton" (click)="openEditStudent(alumno.studentId, alumno.name, alumno.surname, alumno.licennceEnds, alumno.licennceState)"><i class="far fa-edit" ></i></button> <strong>{{alumno.surname}}, {{alumno.name}}</strong></div>
      <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-5 fila-tabla codi"><i *ngIf="alumno.licennceState" class="far fa-check-circle" style="color:limegreen"></i><i *ngIf="!alumno.licennceState" class="far fa-times-circle" style="color:crimson"></i> {{alumno.studentId}}</div>
      <div class="col-xl-3  col-lg-3 col-md-3 col-sm-12 col-7 fila-tabla metricas"><button class="btn_metricas fila_alumno" [disabled]="!alumno.licennceState ? true : null" (click)="openStudentProgress(alumno.studentId)">{{'progresoTxt' | translate}}</button>
                  <!-- <button class="btn_metricas fila_alumno" [disabled]="!alumno.licennceState ? true : null"     (click)="openStudentRobot(alumno.studentId)"  *ngIf="!isUMP()">{{'robotTxt' | translate}}</button> -->
      </div>
      <!-- <div class="col-md-1 col-md-1 col-md-3 col-sm-6 col-6 fila-tabla" *ngIf="alumno.total == 0"><span class="badge bg-success" >{{alumno.total}}</span></div> -->
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 fila-tabla repasar" ><span *ngIf="(alumno.total >  0 && !isUMP()) ||  (alumno.total >  0 && isMates())">{{'aReforzar' | translate}} </span> <div class="badge bg-danger" *ngIf="(alumno.total >  0 && !isUMP()) ||  (alumno.total >  0 && isMates())" >{{alumno.total}}</div></div>
      <div class="col-xl-2 col-lg-2 col-md-1 col-sm-6 col-6 fila-tabla puntrobot"><span></span></div>
    </div>
  </div>   
  <div class="col-12 afegir"> <button type="button" class="btn btn-primary btn-lg  btnNombre" (click)="openAddStudent()"><i class="fas fa-plus"></i> {{'agregarAlumno' | translate}}</button>
  <button type="button" class="btn btn-primary btn-lg  btnNombre descarregar" printSectionId="print-section" ngxPrint [useExistingCss]="true" ><i class="fas fa-download" ></i> {{'descargarTodos' | translate}}</button>
  </div>
  </div>

<div id="modalCrearClase" class="modal" *ngIf=showModal>

    <!-- Modal content -->
      <app-crear-clase *ngIf="createClass"  (eventCreateClaseCompleted)="onModifiClaseCompleted($event)" (eventClaseCloseCompleted)="closeCreateClase()"></app-crear-clase>
      <app-modificar-clase *ngIf="modifyClass"  (eventModifiClaseCompleted)="onModifiClaseCompleted($event)" (eventClaseCloseCompleted)="closeCreateClase()" [nombreClase]="nombreClase" [alumnos]="alumnos" [classId]="classId"></app-modificar-clase>
      <app-agregar-alumno *ngIf="addStudent"  (eventCreateAlumnoCompleted)="onModifiClaseCompleted($event)" (eventClaseCloseCompleted)="closeCreateClase()" [classId]="classId"></app-agregar-alumno>
      <app-modificar-alumno *ngIf="editStudent"  (eventClaseCloseCompleted)="closeCreateClaseWithAction($event)" [studentId]="studentId" [name]="name" [surname]="surname" [licennceEnds] = "licennceEnds" [isValida] = "isValida"></app-modificar-alumno>
  </div>

  <app-footer></app-footer>
  
  


