import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isLoggedIn(): boolean {
    if(localStorage.getItem("userId"))
      if(localStorage.getItem("userId").length > 0 )
        return true;
    
    return false;
  }
}