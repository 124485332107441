<div class="fondo container-fluid">
    <div class="login row">
      <div class="caja p-4">
            <img class="logo" src="../../../../assets/login/logo_itbook.png">
            <div class="iniciaSesion"><p>{{'initSesion' | translate}}</p></div>
            <!-- <div class="space"></div> -->
            <div class="input-group">
                <input type="text" class="form-control" id="username" placeholder="Email" required="">
                <!-- <span class="input-group-text"><i class="fas fa-at"></i></span> -->
            </div>
            <div class="input-group">
                <!-- <span class="input-group-text">@</span> -->
                <input type="text" class="form-control" id="password" placeholder="{{'contrasenya' | translate}}" required="">
                <!-- <span class="input-group-text" id="basic-addon1">
                  <i class="far fa-eye-slash"></i>
                  </span> -->
            </div>
            <div class="warning_text"><p class="errorMsg" id="errorMsg"></p></div>
            <button class="accedeBtn" (click)="onBtnLoginClicked()">{{'acceder' | translate}}</button>
            <button class="recuperarBtn" (click)="onPasswordRecoveryClicked()" >{{'olvidadoContrasena' | translate}}</button>
            <button class="registreBtn " (click)="onBtnRegisterClicked()" ><strong>{{'noCuenta' | translate}}</strong></button>
      
            <div class="dropdown dropdownUser">
              <a class="btn btn-secondary  btnNombre" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <img src= "{{selectedLanguajeImg}}">
              {{languaje}}
              </a>
          

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="#" (click)="onBtnESP()"><img  src="../../../../assets/login/banderita-esp.png"> ESP</a>
                <a class="dropdown-item" href="#" (click)="onBtnVLC()"><img  src="../../../../assets/login/banderita-vlc.png"> VAL</a>
                <a class="dropdown-item" href="#" (click)="onBtnCAT()"><img  src="../../../../assets/login/banderita-cat.png"> CAT</a>
                <a class="dropdown-item" href="#" (click)="onBtnENG()"><img  src="../../../../assets/login/banderita-gb.png"> ENG</a>
              </div>
            </div>
			</div>
      <!-- <div class="disclaimer_emails">
        <p class="texto_disclaimer_emails">{{'avisoRegistroDesdeApp' | translate}}</p>
      </div> -->
    </div>
    <div class="version_container">v1.13_281123</div>
</div>
<app-register *ngIf="showRegister"  (eventRegisterCompleted)="onRegisterCompleted()"></app-register>
<app-recuperar *ngIf="showRecuperar"  (eventRecuperarCompleted)="onRecuperarCompleted()"></app-recuperar>
