

<nav class="topBar container-fluid" >
	<div class="container row g-2">
	<div class="col-md-4 col-12"><a href="/selector"><img class="logo" src="../../../../assets/selector/home.png" ></a></div>
   
     

    <div class="col-md-8 col-12 user">
     <div class="dropdown dropdownUser">
        <a class="btn btn-secondary  btnNombre" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          {{nombreProfesor}}
        </a>
      
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li><a class="dropdown-item" (click)="btnShowCrear()" *ngIf="role">{{'creaCodigo' | translate}}</a></li>
          <li><a class="dropdown-item" (click)="btnShowEditar()">{{'editarCuenta' | translate}}</a></li>

			<li class="dropdown-submenu">
				

				
				
                <a tabindex="-1" href="#" class="dropdown-item" ><img src= "{{selectedLanguajeImg}}">  {{languaje}}</a>
                <ul class="dropdown-menu">
                  <a class="dropdown-item"  (click)="onBtnESP()"><img  src="../../../../assets/login/banderita-esp.png"> ESP</a>
                  <a class="dropdown-item"  (click)="onBtnVLC()"><img  src="../../../../assets/login/banderita-vlc.png"> VAL</a>
                  <a class="dropdown-item"  (click)="onBtnCAT()"><img  src="../../../../assets/login/banderita-cat.png"> CAT</a>
                  <a class="dropdown-item"  (click)="onBtnENG()"><img  src="../../../../assets/login/banderita-gb.png"> ENG</a>
                </ul>
              </li>
              <li><div class="dropdown-divider"></div></li>
              <li><a class="dropdown-item"  (click)="onBtnLogOutClicked()">{{'cerrarSesion' | translate}}</a></li>
        </ul>
      </div>
    </div></div>
  </nav>

  <app-crear-licencias *ngIf="showCrearLicencia"  (eventCerrarLicenciasCompleted)="eventCerrarLicenciasCompleted()" (eventCrearLicenciasCloseCompleted)="eventCrearLicenciasCloseCompleted()"></app-crear-licencias>

  <!-- *ngIf="showEditar" -->
<div id="modalCrearClase" class="modal" *ngIf="showEditar">

  <div class="popup mx-auto">
    <div class="barraSuperiorModal">{{'editarCuentaProfe' | translate}}</div>
    <form class="p-4 p-md-5 bg-light" [formGroup]="formActualizarProfe" autocomplete="off">
      <div class="form-floating mb-3">
            <input type="text" class="form-control" id="nombre" placeholder="NombreAlumno" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <label for="floatingPassword">{{nombre}}</label><i class="icono fas fa-user"></i>
            <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">{{'errorCampoNombre' | translate}}</div>
            </div> -->
          </div>

          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="ApellidoAlumno" placeholder="ApellidoAlumno" formControlName="surname"  [ngClass]="{ 'is-invalid': submitted && f.surname.errors }">
            <label for="floatingPassword">{{apellido}}</label><i class="icono fas fa-user"></i>
            <!-- <div *ngIf="submitted && f.surname.errors" class="invalid-feedback">
              <div *ngIf="f.surname.errors.required">{{'errorCampoApellido' | translate}}</div>
            </div> -->
          </div>
			
			<div class="form-floating mb-3">
            <input type="text" class="form-control" id="MailAlumno" placeholder="MailAlumno" formControlName="emailProfe" [ngClass]="{ 'is-invalid': submitted && f.emailProfe.errors }">
            <label for="floatingPassword">{{email}}</label><i class="icono fas fa-envelope"></i>
            <div *ngIf="submitted && f.emailProfe.errors" class="invalid-feedback">
              <div *ngIf="f.emailProfe.errors.required">{{'errorEmailNecesario' | translate}}</div>
              <div *ngIf="f.emailProfe.errors.email">{{'errorEmailValido' | translate}}</div>
              <div *ngIf="f.emailProfe.errors.matching">{{'errorEmailExiste' | translate}}</div>
            </div>
          </div>
			
			<div class="form-floating mb-3">
            <input type="text" class="form-control" id="PassAlumno" placeholder="PassAlumno" formControlName="password" autocomplete="new-password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <label for="floatingPassword">{{'contrasenya' | translate}}</label><i class="icono far fa-eye-slash"></i>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.minlength">{{'passNeedTxt2' | translate}}</div>
            </div>
          </div>
			
			
			<div class="form-floating mb-3">
            <input type="text" class="form-control" id="ConfirmPassAlumno" placeholder="ConfirmPassAlumno" formControlName="confirmPassword"  [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
            <label for="floatingPassword">{{'contrasenyaConfirmar' | translate}}</label><i class="icono far fa-eye-slash"></i>
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.matching">{{'passNeedTxt4' | translate}}</div>
            </div>
      </div>
    <button type="button" class="btn btn-primary btn-md btnAceptar" (click)="btnEditarProfe()">{{'aceptar' | translate}}</button>
    <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="btnHideEditar()">{{'cancelar' | translate}}</button> 
      
        
    </form>
  </div>

</div>




