import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output, Input} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn, Form } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-crear-clase',
  templateUrl: './crear-clase.component.html',
  styleUrls: ['./crear-clase.component.css']
})
export class CrearClaseComponent implements OnInit {

  @Output() eventCreateClaseCompleted = new EventEmitter<string>();
  @Output() eventClaseCloseCompleted = new EventEmitter();

  @Input() nombreClase = "";
  @Input() alumnos = [];
  @Input() classId = "";

  constructor( private formBuilderMC: FormBuilder,
    private service:LoginService, 
    private router: Router, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
    }

  formEditarClase: FormGroup;
  submitted = false;
 
  ngOnInit(): void {

    this.formEditarClase = this.formBuilderMC.group({
      nameClass: ['',
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)]
    });
  }

  onReset(): void {
    console.log("Reset modificar cklase");
    this.submitted = false;
    this.formEditarClase.reset();
  }

  get mofifyF(): { [key: string]: AbstractControl } {

    // console.log(this.formEditarClase.controls);
    return this.formEditarClase.controls;
  }


  closeCreateClase(){
    this.eventClaseCloseCompleted.emit();
  }

  nonClassName = false;
  createClase(){

    this.submitted = true;
    if (this.formEditarClase.controls.nameClass.invalid) {
      return;
    }

      var newClassname =(document.getElementById('floatingClasSname') as HTMLInputElement).value;
      this.service.createClass(newClassname).subscribe(response => {

        if(parseInt(response['CODE']) == 1){
          response['MESSAGE'].forEach(element => {

            if(element['name'] == newClassname){
              this.eventCreateClaseCompleted.emit(element['classroomId']);
            }
          });

        }else{
          localStorage.clear();
          this.router.navigate(['/login']);
        }

        this.closeCreateClase();
      });
    }

}
