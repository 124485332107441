<main class="fondo">
    <app-top-bar></app-top-bar>
    <app-sub-bar></app-sub-bar>

   <div class="container studenInfo">
	
   <div class=" row">
	   <div class="col-12">
		   <p class="bread"><a href="/clases"><i class="fas fa-chevron-left"></i>{{nombreClase}}</a></p>
		   <h2>{{name}}</h2></div>
		<div class="col-md-6 col-12">
		 <p class="ultimaConexio">{{'ultimaConexioTxt' | translate}} <strong>{{lastConnection}}</strong></p>
        
         </div>
   <div class="col-md-6 col-12">
     <p class="userNameField"><span>{{'licenciaTxt' | translate}}  {{licenciaText}} {{'licenciaValidaTxt' | translate}} {{licenciaEnd}}</span></p>
      
       
     
	</div>
	</div>
	</div>
    <div class="tabsCOntainer container">
		<div class="botonera">
			<div class="botones">
			<button class="btn_metricas fila_alumno activo" *ngIf="!isUMP() && !isMates()">{{'progresoTxt' | translate}}</button>
            <!-- <button class="btn_metricas fila_alumno" (click)="onBtnRobot()" *ngIf="!isUMP() && !isMates()">{{'robotTxt' | translate}}</button> -->
        </div>
        <ul class="nav nav-tabs customTabs justify-content-end nombre-curso" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="1" aria-selected="true" (click)="coursePressed(1)">{{isUMP() ? "1" : 'primeroTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="2" aria-selected="false" (click)="coursePressed(2)">{{isUMP() ? "2" : 'segundoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="3" aria-selected="false" (click)="coursePressed(3)">{{isUMP() ? "3" : 'terceroTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="4" aria-selected="false" (click)="coursePressed(4)">{{isUMP() ? "4" : 'cuartoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="5" aria-selected="false" (click)="coursePressed(5)">{{isUMP() ? "5" : 'quintoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="6" aria-selected="false" (click)="coursePressed(6)">{{isUMP() ? "6" : 'sextoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="isUMP()">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="7" aria-selected="false" (click)="coursePressed(7)">{{isUMP() ? "7" : 'septimoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="isUMP()">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="8" aria-selected="false" (click)="coursePressed(8)">{{isUMP() ? "8" : 'octavoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="isUMP()">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="9" aria-selected="false" (click)="coursePressed(9)">{{isUMP() ? "9" : 'novenoTxt' | translate}} <span *ngIf="!isUMP()">{{'curs' | translate}}</span></button>
            </li>
        </ul></div>
        <div class="tab-content" id="myTabContent">
                <div class="firstRow row" >
					<div class="col-md-9 col-12" style="position:relative;">
                    <div class="txtProgresoGeneral">{{'progresionGeneralTxt' | translate}} &nbsp;&nbsp;</div>
					
                    <div class="progress progressGeneral sombra">
                        <div class="progress-bar barGeneral sombra" role="progressbar" [style.width]=progressGeneral aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
						 
                      </div>
                     <div class="txtProgresoGeneralNumber">&nbsp;&nbsp; {{progressGeneral}}</div>
					<div style="clear:both;"></div>
					</div>
					<div class="col-md-3 col-12">
                      <div class="dropdown dropdownUser" >
                        <a class="btn btn-primary btn-lg px-4 gap-3 dropdown-toggle btnNombre" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {{selectedCat(categorieSelectad)}}
                        </a>
                      
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" >
                          <li *ngFor="let cats of categoriesArray">
                              <a class="dropdown-item" (click)="categoriePressed(cats)" *ngIf="translate.getDefaultLang() == 'es-ES'">{{cats.name}}</a>
                              <a class="dropdown-item" (click)="categoriePressed(cats)" *ngIf="translate.getDefaultLang() == 'va-ES'">{{cats.nameVAL}}</a>
                              <a class="dropdown-item" (click)="categoriePressed(cats)" *ngIf="translate.getDefaultLang() == 'ca-ES'">{{cats.nameVAL}}</a>
                              <a class="dropdown-item" (click)="categoriePressed(cats)" *ngIf="translate.getDefaultLang() == 'en-GB'">{{cats.nameENG}}</a>
                          </li>
						  </ul></div>
                      </div>
			</div>
                

			<div class="tabla">
				<div class="row">
				<div class="col-md-5 col-11 "><h3>{{'actividadesTxt' | translate}}</h3></div>
				<div class="col-md-1 col-1 bloquear check" *ngIf="!isUMP()">
                    <!-- <p> -->
                        {{'reforzarTxtProgreso' | translate}}
                    <!-- </p> -->
                     <!-- <input type="checkbox" class="form-check-input" (change)="onCheckboxChangeGeneral()"> -->
                </div> 
                
					<div class="col-md-5"></div>
				</div>
				<div class="elemento row" *ngFor="let slot of slotsArray">
			<div class="col-md-5 col-11 slotTitulo">{{slot.name}}</div>
				<div class="col-md-1 col-1 check">
                    <!-- <i *ngIf="slot.done" class="far fa-check-circle" style="color:limegreen"></i> -->
                    <input type="checkbox" class="form-check-input" (change)="onCheckboxChangeSpec(slot.slotId)" [checked]="slot.done==null?false:slot.done?false:true" *ngIf="!isUMP()">  <!-- (change)="onCheckboxChangeSpec(slot.id)" -->
                 </div>
				<div class="col-md-5 col-10 barraprogreso"> <div class="progress sombra">
                               <div class="progress-bar sombra" role="progressbar" [style.width]=getwidth(slot.stars) [style.background-color]=getColor(slot.stars) aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>  
                            </div></div>
                <div class="col-md-1 col-2 percent">{{getwidth(slot.stars)}}</div>
				</div>
			
			
			</div>
			
			

        </div>
    </div>
</main>
<app-footer></app-footer>
