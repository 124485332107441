import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn } from '@angular/forms';
import { Router } from "@angular/router";
import Validation from 'src/app/utils/Validation';
import { LoginService } from 'src/app/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-crear-licencias',
  templateUrl: './crear-licencias.component.html',
  styleUrls: ['./crear-licencias.component.css']
})
export class CrearLicenciasComponent implements OnInit {


  @Output() eventCerrarLicenciasCompleted = new EventEmitter();
  @Output() eventCrearLicenciasCloseCompleted = new EventEmitter();

  constructor(private router: Router, private formBuilder: FormBuilder,  private service:LoginService, public translate: TranslateService) { 
    translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
  }
    
  formActualizarProfe: FormGroup;
  submitted = false;

  proyects;
  ngOnInit(): void {

    this.formActualizarProfe = this.formBuilder.group(
      {
        name: ['',
            Validators.required,
            Validators.min(1),
            Validators.max(100)]
      });

    this.service.getProyects()
    .subscribe(response => {
      this.proyects = response;
    });

  }

  btnHideCrear(){
    this.eventCerrarLicenciasCompleted.emit();

  }




  onReset(): void {
    this.submitted = false;
    this.formActualizarProfe.reset();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formActualizarProfe.controls;
  }

  btnCrear(){

    this.submitted = true;


    if (this.formActualizarProfe.controls.name.invalid) {
      console.log(this.formActualizarProfe.controls.name.invalid);

      return;
    }

    // console.log("btnCrear " + this.f.name.value + " " + (document.getElementById("proyectoInoput") as HTMLInputElement).value);

    this.service.crearLicencias( (document.getElementById("proyectoInoput") as HTMLInputElement).value, this.f.name.value, localStorage.getItem("email")).subscribe(response => {
      console.log(response);
      const blob = new Blob([JSON.stringify(response)], {type : 'application/json'});
      // const url = window.URL.createObjectURL(blob);
      // window.open(url);

      var link = document.createElement("a"); // Or maybe get it from the current document
      link.id ="HelperDownload"+Date.now().toString();
      link.href = window.URL.createObjectURL(blob);;
      link.download = "Licencias"+new Date()+".json";
      document.body.appendChild(link); // Or append it whereever you want
      document.getElementById(link.id).click(); //can add an id to be specific if multiple anchor tag, and use #id
      document.getElementById(link.id).hidden = true;
      this.eventCrearLicenciasCloseCompleted.emit();
    });



  }

}
