
<div class="barraProyecto"  [style.background-color]=backgroundColor >

		<div class="container">
	<div id="tituloProyectoHelp" class="txtProyecto col-12">{{tituloProyecto}}</div>
	

</div>

</div>
<!-- ; 'background-color' : +getBackgroundColor() -->