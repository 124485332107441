import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn, Form } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { TopBarComponent } from '../top-bar/top-bar.component';
import { SubBarComponent } from '../sub-bar/sub-bar.component';
import { FooterComponent } from '../footer/footer.component';
import { ModificarClaseComponent } from './modificar-clase/modificar-clase.component';
import { CrearClaseComponent } from './crear-clase/crear-clase.component';
import { AgregarAlumnoComponent } from './agregar-alumno/agregar-alumno.component';
import { ModificarAlumnoComponent } from './modificar-alumno/modificar-alumno.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clases',
  templateUrl: './clases.component.html',
  styleUrls: ['./clases.component.css']
})
export class ClasesComponent implements OnInit {

  constructor(private service:LoginService, 
    private router: Router, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-es']);
    }

  @ViewChild(TopBarComponent,  {static: true}) topBarComponent!: TopBarComponent;
  @ViewChild(SubBarComponent,  {static: true}) subBarComponent!: SubBarComponent;
  @ViewChild(FooterComponent,  {static: true}) footerComponent!: FooterComponent;
  @ViewChild(ModificarClaseComponent,  {static: true}) modificarClaseComponent!: ModificarClaseComponent;
  @ViewChild(CrearClaseComponent,  {static: true}) crearClaseComponent!: CrearClaseComponent;
  @ViewChild(AgregarAlumnoComponent,  {static: true}) agregarAlumnoComponent!: AgregarAlumnoComponent;
  @ViewChild(ModificarAlumnoComponent,  {static: true}) modificarAlumnoComponent!: ModificarAlumnoComponent;



  nombreClase = "Clase ejemplo";
  clases:any;
  alumnos:any;
  classId = "";
  ngOnInit(): void {

    if(localStorage.getItem("claseId") != null){
      console.log("Vamos a clase " + localStorage.getItem("claseId"));
      // this.classClicked(localStorage.getItem("claseId"));
      this.getClasess(localStorage.getItem("claseId"));
    }else{
      console.log("No tengo clase");
      this.getClasess(null);
    }

    if(localStorage.getItem("idioma")){
      
      if(localStorage.getItem("idioma")== "ESP"){
        this.translate.setDefaultLang('es-ES');
      }
  
      if(localStorage.getItem("idioma") == "ENG"){
        this.translate.setDefaultLang('en-GB');
      }
  
      if(localStorage.getItem("idioma") == "VAL"){
        this.translate.setDefaultLang('va-ES');
      }

      if(localStorage.getItem("idioma") == "CAT"){
        this.translate.setDefaultLang('ca-ES');
      }

    }else{
      localStorage.setItem("idioma", "ESP");
      this.translate.setDefaultLang('es-ES');

    }
  }


  getClasess(classID:string  ){
    this.service.getClases().subscribe(response => {
      if(parseInt(response['CODE']) == 1){
        this.clases = response['MESSAGE'];


        if(classID){
          this.classClicked(classID);
          console.log("Nombre de clase que recibo " + this.nombreClase);
        }else{
          this.nombreClase = response['MESSAGE'][0]['name'];
          this.getAlumnos(response['MESSAGE'][0]['classroomId']);
          this.classId = response['MESSAGE'][0]['classroomId'];
          
        }
      }else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  getAlumnos(classID:string){

    this.service.getAlumnos(classID)
    .subscribe(response => {
      if(parseInt(response['CODE']) == 1){
        this.alumnos = response['MESSAGE'];
        this.classId = classID;
      }else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }

  });
}

  classClicked(classID:string ){

    this.clases.forEach(element => {
      if(element.classroomId == classID){
        this.nombreClase = element.name;
        this.classId = classID;
        // console.log("classClicked: " + classID);
        this.getAlumnos(classID);
        
      }
    });

  }

  showModal = false;
  createClass = false;
  modifyClass = false;
  addStudent = false;
  editStudent= false;

  openModalCreateClase(){
    this.showModal = true;
    this.createClass = true;

  }
  openModifyClass(){
    this.showModal = true;
    this.modifyClass = true;

  }

  openAddStudent(){
    this.showModal = true;
    this.addStudent = true;
  }
  studentId ="";
  name ="";
  surname ="";
  licennceEnds ="";
  isValida = false;
  openEditStudent(alumnoId: string, name: string, surname: string, licennceEnds:string, licennceState: boolean){
    this.isValida = licennceState;
    this.licennceEnds= (licennceEnds) ?licennceEnds.split('T')[0]:"";
    this.studentId = alumnoId;
    this.name = name;
    this.surname = surname;
    this.showModal = true;
    this.editStudent = true;

  }

  closeCreateClaseWithAction(event){
    if(event){
      this.getAlumnos(this.classId);
    }

    this.closeCreateClase();
  }
  
  closeCreateClase(){
    this.showModal = false;
    this.createClass = false;
    this.modifyClass = false;
    this.addStudent = false;
	  this.editStudent= false;


  }

  openStudentProgress(alumnoId: string){
    localStorage.setItem("clase", this.nombreClase);
    localStorage.setItem("claseId", this.classId);

    this.router.navigate(['/progress/' + alumnoId]);
  }

  openStudentRobot(alumnoId: string){
    localStorage.setItem("clase", this.nombreClase);
    localStorage.setItem("claseId", this.classId);

    this.router.navigate(['/robot/' + alumnoId]);
  }


  onModifiClaseCompleted(identificadorClase: string){

    this.getClasess(identificadorClase);
    this.closeCreateClase();
  }

  isUMP(){
    var proyect = localStorage.getItem("proyect");
    if(proyect == "com.itbook.unmondeparaules" || proyect == "com.itbook.aula_matematicas")
      return true;
    else
      return false;
  }
  
  isMates(){
    var proyect = localStorage.getItem("proyect");
    if(proyect == "com.itbook.aula_matematicas")
      return true;
    else
      return false;
  }
}
