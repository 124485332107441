import { Component, OnInit, ViewChild, ViewChildren, HostListener} from '@angular/core';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { SubBarComponent } from '../sub-bar/sub-bar.component';
import { LoginService } from 'src/app/services/login/login.service';
import { Router, ActivatedRoute } from "@angular/router";
import { FooterComponent } from '../footer/footer.component';
import { TranslateService } from '@ngx-translate/core';

class Errores {
  name: string;
  numError: number;
  categoria: string;
  recomendado: string;

}

@Component({
  selector: 'app-robot',
  templateUrl: './robot.component.html',
  styleUrls: ['./robot.component.css']
})


export class RobotComponent implements OnInit {
  studenId = "";

  constructor(private service:LoginService, 
    private router: Router,private actRoute: ActivatedRoute,
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
      this.studenId = this.actRoute.snapshot.params.id;
    }

  // @HostListener('document:click', ['$event'])
  @ViewChild(TopBarComponent,  {static: true}) topBarComponent!: TopBarComponent;
  @ViewChild(SubBarComponent,  {static: true}) subBarComponent!: SubBarComponent;
  @ViewChild(FooterComponent,  {static: true}) footerComponent!: FooterComponent;
  
  // @HostListener('click') mouseover(eventData: Event) {
  //   // this.erroresMenu  = true;
  //   console.log(eventData);
  // }


  progressGeneral:string = "";
  sessionsArray:any;
  name:string = "";
  lastConnection:string = "";
  licenciaText:string = "";
  points:string = "";
  aciertosText:string = "";
  fallosText:string = "";
  roborImg:string = "";
  nombreClase:string = "BACK";
  aciertos= "0%";
  errores= "0%";
  licenciaEnd ="";

  
  ngOnInit(): void {

    if(localStorage.getItem("idioma")){
      if(localStorage.getItem("idioma")== "ESP"){
        this.translate.setDefaultLang('es-ES');
      }
  
      if(localStorage.getItem("idioma") == "ENG"){
        this.translate.setDefaultLang('en-GB');
      }
  
      if(localStorage.getItem("idioma") == "VAL"){
        this.translate.setDefaultLang('va-ES');
      }

      if(localStorage.getItem("idioma") == "CAT"){
        this.translate.setDefaultLang('ca-ES');
      }

    }else{
      localStorage.setItem("idioma", "ESP");
      this.translate.setDefaultLang('es-ES');

    }

    
    this.getStudentData();
    this.nombreClase = localStorage.getItem("clase");

  }
  arrayCalculoRankingHTML = [];
  arrayCalculoRankingHTMLHelp = [];
  getStudentData(){
    this.service.getStudentRobot(this.studenId).subscribe(response => {
      // console.log(response);
      if(parseInt(response['CODE']) == 1){


        this.name = response['STUDENT']['name'] + " "+response['STUDENT']['surname'];

        var d = new Date(response['STUDENT']['updatedAt']);
        this.lastConnection = d.toLocaleDateString(this.translate.getDefaultLang(), { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric'}).toString();

 
        this.licenciaText = response['LICENCE']['licenceId'];
        this.licenciaEnd = response['LICENCE']['endDate'].split('T')[0];   

        this.sessionsArray = response['PROGRESS'];
        this.points = response['POINTS'];

        var stars = 0;
        var sesiones = 0

        var arrayCalculoRanking = [];

        this.sessionsArray.forEach(element => {
          stars = stars + element.success;
          sesiones++;

          //creo que aquí tengo que hacer el calculo del ranquing al menos añadirlos a un array
          var arr = element.slotIdFailed.replace('[', '').replace(']', '').split(',');
          if(arr.length > 0){
            arr.forEach(element => {
              arrayCalculoRanking.push(element);
            });
          }


        });

        var map = arrayCalculoRanking.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});

        this.service.studentGetSlotName(arrayCalculoRanking.toString(), localStorage.getItem("idioma")).subscribe(response => {  
          // console.log(response);

          response['SLOTS'][0].forEach(element => {
            var auxObject = new Errores();
            auxObject.name= element['name'];
            auxObject.numError= map[element['slotId']];
            auxObject.categoria= element['categoria'].toUpperCase();
            auxObject.recomendado= element['refuerzo'];
            this.arrayCalculoRankingHTML.push(auxObject);
          });

          this.arrayCalculoRankingHTML.sort((a, b) => (a.numError > b.numError) ? -1 : 1);
          // console.log

          this.listadoErroresNumero = (this.arrayCalculoRankingHTML.length > 5)? "5":this.arrayCalculoRankingHTML.length.toString();
          this.arrayCalculoRankingHTMLHelp= this.arrayCalculoRankingHTML.slice(0, 5);

        });



        
        var auxAciertos = stars;
        this.aciertos = Math.floor((auxAciertos * 100)/(sesiones*20)).toString() + "%";
         this.errores = (100- Math.floor((auxAciertos * 100)/(sesiones*20))).toString() + "%";

        this.aciertosText = Math.floor((stars*100)/(sesiones*20)).toString()+"%";
        this.fallosText = (100-Math.floor((stars*100)/(sesiones*20))).toString()+"%";

        this.progressGeneral = (response['POINTS']*100/600).toString()+"%";
        this.points = response['POINTS'];
        // console.log("stars: " + response['POINTS']);


        if(response['POINTS'] < 100){
          this.roborImg = "../../../assets/robot/01_robot_web.png";
        }
        if(response['POINTS'] < 199 && response['POINTS'] >= 100){
          this.roborImg = "../../../assets/robot/02_robot_web.png";
        }
        if(response['POINTS'] < 299 && response['POINTS'] >= 200){
          this.roborImg = "../../../assets/robot/03_robot_web.png";          
        }
        if(response['POINTS'] < 399 && response['POINTS'] >= 300){
          this.roborImg = "../../../assets/robot/04_robot_web.png";         
        }
        if(response['POINTS'] < 499 && response['POINTS'] >= 400){
          this.roborImg = "../../../assets/robot/05_robot_web.png";          
        }
        if(response['POINTS'] >= 500){
          this.roborImg = "../../../assets/robot/06_robot_web.png";          
        }

      }else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
    
  }


  getwidth(stars){
    var returnValue = stars*100/20 ;
    return returnValue+"%";
  }
  getColor(stars){

    // if(stars <= 7){
    //   return "#dc3545";
    // }
    // if(stars <= 14){
    //   return "#ffc107";
    // }

    return "#28a745";
  }

  returnDate(date:string){
    const event = new Date(date);
    var fechaDevolver = event.toLocaleDateString(this.translate.getDefaultLang(), { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }).toString();
    return fechaDevolver[0].toUpperCase() + fechaDevolver.substr(1,fechaDevolver.length-1);
  }

  onBtnProgreso(){
    this.router.navigate(['/progress/'+this.studenId]);
  }


  listaFallos = [];
  toogleEstado = false;
  fechaFlotante = "";
  hoverScore(session){

    var fechaDevolver = (new Date(session.createdAt)).toLocaleDateString(this.translate.getDefaultLang(), { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric'}).toString();
    this.fechaFlotante = fechaDevolver[0].toUpperCase() + fechaDevolver.substr(1,fechaDevolver.length-1);


    var arr = session.slotIdFailed.replace('[', '').replace(']', '').split(',');
    var map = arr.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
    this.listaFallos = [];
    if(session.slotIdFailed.split(',').length > 1){
      
      var slotsArray = session.slotIdFailed.toString().replace('[', '').replace(']', '');
      this.service.studentGetSlotName(slotsArray, localStorage.getItem("idioma")).subscribe(response => {
        // console.log(response);

        response['SLOTS'][0].forEach(element => {
          var auxObject = new Errores();
          auxObject.name= element['name'];
          auxObject.numError= map[element['slotId']];
          // console.log(auxObject.name + " "+map[element['slotId']]);

          this.listaFallos.push(auxObject);
          this.toogleEstado = true;
        });

      });
    }
  }

  ocultar(){
    // (document.getElementById("tooltip_"+session.id) as HTMLElement).hidden = true;
    this.toogleEstado = false;
    this.listaFallos = [];
  }

  erroresMenu  = "none";
  listadoErroresNumero = "0";
  desplegarErrores(){
    if(this.erroresMenu  == "block")
      this.erroresMenu  = "none";
    else
      this.erroresMenu  = "block";
    
    // console.log(this.erroresMenu);
  }

  mostrarErrores(numero:number){
    if(numero == 999){
      this.listadoErroresNumero = "Todos";
      this.arrayCalculoRankingHTMLHelp= this.arrayCalculoRankingHTML;
    }else{
      this.listadoErroresNumero = numero.toString();
      this.arrayCalculoRankingHTMLHelp= this.arrayCalculoRankingHTML.slice(0, numero);
    }
  }
}
