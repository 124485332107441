import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login/login.component';
import { SelectorComponent } from './views/selector/selector.component';
import { ClasesComponent } from './views/clases/clases.component';
import { ProgressComponent } from './views/progress/progress.component';
import { RobotComponent } from './views/robot/robot.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {path: '', redirectTo:'/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'selector', component: SelectorComponent, canActivate: [AuthGuard] },
  {path: 'progress/:id', component: ProgressComponent, canActivate: [AuthGuard] },
  {path: 'robot/:id', component: RobotComponent, canActivate: [AuthGuard] },
  {path: 'clases', component: ClasesComponent, canActivate: [AuthGuard] }];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthService]
})
export class AppRoutingModule { }
