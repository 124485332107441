<div id="modalCrearClase" class="modal">

    <div class="popup mx-auto">
      <div class="barraSuperiorModal">{{'crearLicencias' | translate}}</div>
      <form class="p-4 p-md-5 bg-light" [formGroup]="formActualizarProfe" autocomplete="off">
        <div class="form-floating mb-3">
              <input type="number" class="form-control" id="nombre" placeholder="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <label for="floatingPassword">{{'numeroLicencias' | translate}}</label><i class="icono fa fa-rocket"></i>
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Introducir un número válido (1-100)</div>
              </div>
            </div>
  
            <div class="form-floating mb-3">
                <select class="form-control" id="proyectoInoput">
                        <option *ngFor="let proyect of proyects" [value]="proyect.proyectId">{{proyect.name}}</option>
                </select>
            </div>
   
      <button type="button" class="btn btn-primary btn-md btnAceptar" (click)="btnCrear()">{{'aceptar' | translate}}</button>
      <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="btnHideCrear()">{{'cancelar' | translate}}</button> 
        
          
      </form>
    </div>
  
  </div>