import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output, Input} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn, Form } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agregar-alumno',
  templateUrl: './agregar-alumno.component.html',
  styleUrls: ['./agregar-alumno.component.css']
})
export class AgregarAlumnoComponent implements OnInit {

  @Output() eventCreateAlumnoCompleted = new EventEmitter<string>();
  @Output() eventClaseCloseCompleted = new EventEmitter();
  @Input() classId = "";

  constructor( private formBuilderMC: FormBuilder,
    private service:LoginService, 
    private router: Router, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
    }

    formAnyadirAlumno: FormGroup;
    submitted = false;
  
  ngOnInit(): void {

    this.formAnyadirAlumno = this.formBuilderMC.group({
      licencia: ['',
          [Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)]],
      name: ['',
          [Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)]],
      surname: ['',
          [Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)]],

    });
  }

  onReset(): void {
    console.log("Reset modificar cklase");
    this.submitted = false;
    this.formAnyadirAlumno.reset();
  }

  get mofifyF(): { [key: string]: AbstractControl } {

    // console.log(this.formEditarClase.controls);
    return this.formAnyadirAlumno.controls;
  }

  closeCreateClase(){
    this.eventClaseCloseCompleted.emit();
  }

  onSubmit(values:any) {
    
    this.createStudent();
  }

  createStudent(){

    this.submitted = true;

    if (this.mofifyF.licencia.errors|| this.mofifyF.name.errors || this.mofifyF.surname.errors) {
      return;
    }

      this.service.createStudent(this.mofifyF.licencia.value, this.mofifyF.name.value,this.mofifyF.surname.value, this.classId ).subscribe(response => {
        if(parseInt(response['CODE']) == 1){
          // this.clases = response['MESSAGE'];
          this.eventCreateAlumnoCompleted.emit(this.classId);
        }else{
          if(parseInt(response['CODE']) == -1 || parseInt(response['CODE']) == -2){
            this.mofifyF.licencia.setErrors({ matching: true });
          }else{
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        }

    
      });
   }
  

}
