<div class="popup mx-auto">
    <div class="barraSuperiorModal">{{'agregarAlumno' | translate}}</div>
    <form class="p-4 p-md-5 bg-light" [formGroup]="formAnyadirAlumno" (ngSubmit)="onSubmit(formAnyadirAlumno.value)">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="licenciaName" placeholder="licencia" formControlName="licencia" [ngClass]="{ 'is-invalid': submitted && mofifyF.licencia.errors }">
        <label for="floatingPassword">{{'licenciaTxt' | translate}}</label><i class="icono fas fa-lock-open"></i>
        <div *ngIf="submitted && mofifyF.licencia.errors" class="invalid-feedback">
            <div *ngIf="mofifyF.licencia.errors.required">{{'errorLicenciaIntruducir' | translate}}</div>
            <div *ngIf="mofifyF.licencia.errors.matching">{{'errorLicenciaValida' | translate}}</div>
        </div>
      </div>
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="NombreAlumno" placeholder="NombreAlumno" formControlName="name" [ngClass]="{ 'is-invalid': submitted && mofifyF.name.errors }">
        <label for="floatingPassword">{{'nombreTxt' | translate}}</label><i class="icono fas fa-user"></i>
        <div *ngIf="submitted && mofifyF.name.errors" class="invalid-feedback">
            <div *ngIf="mofifyF.name.errors.required">{{'errorCampoNombre' | translate}}</div>
            <div *ngIf="mofifyF.name.errors.minlength">{{'errorDosCaracteres' | translate}}</div>
        </div>
      </div>
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="ApellidoAlumno" placeholder="ApellidoAlumno" formControlName="surname" [ngClass]="{ 'is-invalid': submitted && mofifyF.surname.errors }">
        <label for="floatingPassword">{{'apellidoTxt' | translate}}</label><i class="icono fas fa-user"></i>
        <div *ngIf="submitted && mofifyF.surname.errors" class="invalid-feedback">
            <div *ngIf="mofifyF.surname.errors.required">{{'errorCampoApellido' | translate}}</div>
            <div *ngIf="mofifyF.name.errors.minlength">{{'errorDosCaracteres' | translate}}</div>
        </div>
      </div>
      <div class="form-floating mb-3">&nbsp;</div>
      <button type="submit" class="btn btn-primary btn-md btnAceptar">{{'aceptar' | translate}}</button>
      <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="closeCreateClase()">{{'cancelar' | translate}}</button>
      

    </form>
  </div>