import { Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { RegisterComponent } from '../register/register.component';
import { RecuperarComponent } from '../recuperar/recuperar.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild(RegisterComponent,  {static: true}) registerComponent!: RegisterComponent;
  @ViewChild(RecuperarComponent,  {static: true}) recuperarComponent!: RecuperarComponent;

  constructor(private service:LoginService, 
              private router: Router, 
              public translate: TranslateService) {
                translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
                translate.setDefaultLang('es-ES');
              }


  languaje = "ESP";
  selectedLanguajeImg = "../../../../assets/login/banderita-esp.png";
  version:string = "1.0.03_03_2023_9_50";
  ngOnInit(): void {
    
    console.log("Version: " + this.version);

    if(localStorage.getItem("idioma")){
      
      this.languaje = localStorage.getItem("idioma");

      if(this.languaje == "ESP"){
        this.translate.setDefaultLang('es-ES');
      }
  
      if(this.languaje == "ENG"){
        this.translate.setDefaultLang('en-GB');
      }
  
      if(this.languaje == "VAL"){
        this.translate.setDefaultLang('va-ES');
      }

      if(this.languaje == "CAT"){
        this.translate.setDefaultLang('ca-ES');
      }

    }else{
      this.languaje = "ESP";
      this.translate.setDefaultLang('es-ES');

    }

    this.selectedLanguajeImgFunc();
  }

  selectedLanguajeImgFunc(){
    if(this.languaje == "ESP"){
      this.selectedLanguajeImg =  "../../../../assets/login/banderita-esp.png";
      this.translate.setDefaultLang('es');
    }

    if(this.languaje == "ENG"){
      this.selectedLanguajeImg = "../../../../assets/login/banderita-gb.png";
      this.translate.setDefaultLang('en');
    }

    if(this.languaje == "VAL"){
      this.selectedLanguajeImg = "../../../../assets/login/banderita-vlc.png";
      this.translate.setDefaultLang('va');
    }

    if(this.languaje == "CAT"){
      this.selectedLanguajeImg = "../../../../assets/login/banderita-cat.png";
      this.translate.setDefaultLang('ca');
    }
  }

  onBtnLoginClicked(){

    var username =  (document.getElementById('username') as HTMLInputElement).value;
    var password =  (document.getElementById('password') as HTMLInputElement).value;

    var errerText = "";
    var errorData = false;
    if(username.length <= 1 || password.length <= 1){
      errerText = "Es necesario escribir la dirección de correo. "
      errorData = true;
    }
    if(password.length <= 1){
      errerText = errerText +"</br>"+" Es necesario escribir la contraseña."
      errorData = true;
    }
    if(errorData){
      document.getElementById('errorMsg').innerHTML = errerText;
    }else{
      this.service.login(username, password).subscribe(response => {

        if(parseInt(response['CODE']) == 1){
          //Todo OK
          // console.log(response['USER']);
          // console.log(response['USER']['isValidated']);
          if(!response['USER']['isValidated']){
            document.getElementById('errorMsg').innerHTML = "Por favor valide el correo electronico para poder continuar.";

          }else{
            localStorage.setItem("token", response['USER']['token']);
            localStorage.setItem("userId", response['USER']['userId']);
            localStorage.setItem("firstName", response['USER']['firstName']);
            localStorage.setItem("lastName", response['USER']['lastName']);
            localStorage.setItem("email", response['USER']['email']);
            localStorage.setItem("role", response['USER']['role']);

            localStorage.setItem("userName", response['USER']['firstName'] + " " +response['USER']['lastName']);
            this.router.navigate(['/selector']);
            


          }
        }else{
          // console.log(response['MESSAGE']);
          document.getElementById('errorMsg').innerHTML = response['MESSAGE'];


        }
      });
    }


    setTimeout(()=>{
      document.getElementById('errorMsg').innerHTML = "";
    }, 3000);

  }

  showRegister = false;
  showRecuperar = false;

  onBtnRegisterClicked(){
  this.showRegister = true;
  }



  onPasswordRecoveryClicked(){
    this.showRecuperar = true;
  }

  onRecuperarCompleted(){
    this.showRecuperar = false;

  }

  onRegisterCompleted(){
    this.showRegister = false;
  }

  onBtnVLC(){
    localStorage.setItem("idioma", "VAL");
  }

  onBtnCAT(){
    localStorage.setItem("idioma", "CAT");
  }

  onBtnESP(){
    localStorage.setItem("idioma", "ESP");
  }

  onBtnENG(){
    localStorage.setItem("idioma", "ENG");
  }
}
