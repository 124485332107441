import { Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { TopBarComponent } from '../top-bar/top-bar.component';
import { FooterComponent } from '../footer/footer.component';
import { SubBarComponent } from '../sub-bar/sub-bar.component';



@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit {

  @ViewChild(TopBarComponent,  {static: true}) topBarComponent!: TopBarComponent;
  @ViewChild(FooterComponent,  {static: true}) footerComponent!: FooterComponent;
  @ViewChild(SubBarComponent,  {static: true}) subBarComponent!: SubBarComponent;

  constructor(private service:LoginService, 
    private router: Router) { }


  proyects:any;

  ngOnInit(): void {
    localStorage.setItem("proyect", "");
    // localStorage.setItem("claseId", null);

    this.service.getProyects()
    .subscribe(response => {
      this.proyects = response;
    });
  }

  onAppSelected(proyect){
    if(proyect.isAvaliable <= 0)
      return;
      
    localStorage.setItem("proyect",proyect.proyectId);
    // if(proyect.proyectId == "com.itbook.unmondeparaules")
    //   this.router.navigate(['/clasesUMP']);
    // else
      this.router.navigate(['/clases']);
    
  }

}
