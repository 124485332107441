import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login/login.component';
import { RegisterComponent } from './views/login/register/register.component';
import { SelectorComponent } from './views/selector/selector.component';
import { ClasesComponent } from './views/clases/clases.component';
import { TopBarComponent } from './views/top-bar/top-bar.component';
import { SubBarComponent } from './views/sub-bar/sub-bar.component';
import { ProgressComponent } from './views/progress/progress.component';
import { RobotComponent } from './views/robot/robot.component';
import { FooterComponent } from './views/footer/footer.component';
import { RecuperarComponent } from './views/login/recuperar/recuperar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModificarClaseComponent } from './views/clases/modificar-clase/modificar-clase.component';
import { CrearClaseComponent } from './views/clases/crear-clase/crear-clase.component';
import { AgregarAlumnoComponent } from './views/clases/agregar-alumno/agregar-alumno.component';
import { CrearLicenciasComponent } from './views/top-bar/crear-licencias/crear-licencias.component';
import { ModificarAlumnoComponent } from './views/clases/modificar-alumno/modificar-alumno.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {NgxPrintModule} from 'ngx-print';
// import { I18nModule } from './i18n/i18n.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    SelectorComponent,
    ClasesComponent,
    TopBarComponent,
    SubBarComponent,
    ProgressComponent,
    RobotComponent,
    FooterComponent,
    RecuperarComponent,
    ModificarClaseComponent,
    CrearClaseComponent,
    AgregarAlumnoComponent,
    CrearLicenciasComponent,
    ModificarAlumnoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}