import { CloneVisitor } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sub-bar',
  templateUrl: './sub-bar.component.html',
  styleUrls: ['./sub-bar.component.css']
})
export class SubBarComponent implements OnInit {

  tituloProyecto ="";
  backgroundColor = "";
  urlImgBackground = "";

  constructor(public translate: TranslateService) { 
    translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
  }

  ngOnInit(): void {
    this.backgroundColor = "#387780";
    this.tituloProyecto = "Aula";

    console.log("Proyect Id: " + localStorage.getItem("proyect"));
    switch(localStorage.getItem("proyect")){
      case "com.itbook.aula_lengua_valenciano":
        this.tituloProyecto = "Valencià";
        this.backgroundColor = "#bcce01";
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;
      case "com.itbook.aula_lengua_castellano":
        this.tituloProyecto =  "Lengua";
        this.backgroundColor = "#ffc401"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";

      break;

      case "com.itbook.aula_matematicas":
        this.tituloProyecto =  "Aula Matemáticas";
        if(localStorage.getItem("idioma")){
          this.translate.get('aulaMatematicas').subscribe((translated: string) => {
            console.log("Mates: ", translated);
            this.tituloProyecto = translated;
          });
        }
        this.backgroundColor = "#6bc7d4"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";

      break;

      case "com.itbook.unmondeparaules":
        this.tituloProyecto =  "Un món de paraules";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";

      break;

      case "itbook_my_life_in_english.png":
        this.tituloProyecto =  "My life in english";
        this.backgroundColor = "#76aadb"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";

      break;

      case "com.itbook.aula_lengua_ingles":
        this.tituloProyecto =  "Aula English";
        this.backgroundColor = "#ffc401"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";

      break;

      case "com.itbook.aula_infantil":
        this.tituloProyecto =  "Infantil";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_comprension":
        this.tituloProyecto =  "Comprensión lectora";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_naturales":
        this.tituloProyecto =  "Ciencias de la naturaleza";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_sociales":
        this.tituloProyecto =  "Ciencias sociales";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_valores":
        this.tituloProyecto =  "Valores sociales y cívicos";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_religion":
        this.tituloProyecto =  "Religión";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_ef":
        this.tituloProyecto =  "Educación física";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;

      case "com.itbook.aula_musica":
        this.tituloProyecto =  "Música";
        this.backgroundColor = "#387780"
        // this.urlImgBackground = "../../../assets/mockup_manager_llengua_valenciana/aula_valencia_min.png";
      break;
    }
  }

}

