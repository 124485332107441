
<div id="modalCrearClase" class="modal">

      <div class="popup mx-auto">
        <div class="barraSuperiorModal">{{'registroTxt' | translate}}</div>
        <form class="p-4 p-md-5 bg-light" [formGroup]="form" autocomplete=off>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="NombreAlumno" placeholder="NombreAlumno" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <label for="floatingPassword">{{'nombreTxt' | translate}}</label><i class="icono fas fa-user"></i>
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">{{'errorCampoNombre' | translate}}</div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="ApellidoAlumno" placeholder="ApellidoAlumno" formControlName="surname" [ngClass]="{ 'is-invalid': submitted && f.surname.errors }">
            <label for="floatingPassword">{{'apellidoTxt' | translate}}</label><i class="icono fas fa-user"></i>
            <div *ngIf="submitted && f.surname.errors" class="invalid-feedback">
              <div *ngIf="f.surname.errors.required">{{'errorCampoApellido' | translate}}</div>
            </div>
          </div>
			
          <div class="form-floating mb-3">
                <input type="text" class="form-control" id="MailProfe" placeholder="MailProfe" formControlName="emailProfe" (blur)="onMailProfe()" [ngClass]="{ 'is-invalid': submitted && f.emailProfe.errors }">
                <label for="floatingPassword">Email</label><i class="icono fas fa-envelope"></i>
                <div *ngIf="submitted && f.emailProfe.errors" class="invalid-feedback">
                  <div *ngIf="f.emailProfe.errors.required">{{'errorEmailNecesario' | translate}}</div>
                  <div *ngIf="f.emailProfe.errors.email">{{'errorEmailValido' | translate}}</div>
                  <div *ngIf="f.emailProfe.errors.matching">{{'errorEmailExiste' | translate}}</div>

                </div>
          </div>
			
			
			<!-- <div class="form-floating mb-3">
            <input type="text" class="form-control" id="ConfirmMailAlumno" placeholder="ConfirmMailAlumno" formControlName="email2" required>
            <label for="floatingPassword">Confirmar Email</label><i class="icono fas fa-envelope"></i>
         
          </div> -->
			
			
			<div class="form-floating mb-3">
            <input type="password" class="form-control" id="PassAlumno" placeholder="PassAlumno" formControlName="password" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            <label for="floatingPassword">{{'contrasenya' | translate}}</label><i class="icono far fa-eye-slash"></i>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">{{'passNeedTxt' | translate}}</div>
                <div *ngIf="f.password.errors.minlength">{{'passNeedTxt2' | translate}}</div>
                <div *ngIf="f.password.errors.maxlength">{{'passNeedTxt3' | translate}}</div>
            </div>
          </div>
			
			
			<div class="form-floating mb-3">
            <input type="password" class="form-control" id="ConfirmPassAlumno" placeholder="ConfirmPassAlumno" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
            <label for="floatingPassword">{{'contrasenyaConfirmar' | translate}}</label><i class="icono far fa-eye-slash"></i>
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">{{'passNeedTxt' | translate}}</div>
              <div *ngIf="f.confirmPassword.errors.matching">{{'passNeedTxt4' | translate}}</div>
          </div>
          </div>
			
			
			<div class="form-floating mb-3"> <div class="input-group" style="margin:20px 0;">
                
                <p style="margin: 0;"><span class="input-group-text eliminar">
                    <input id="registrarCheckbox" type="checkbox" class="form-check-input mt-0" (change)="onCheckboxChange()">
                </span>{{'condiciones1Txt' | translate}}<a href="#">{{'condiciones2Txt' | translate}}</a></p>
			   
            </div></div>
			
        <button type="button" class="btn btn-primary btn-md btnAceptar" (click)="onBtnRegistrarse()" [disabled]=!showRegistro >{{'registrarseTxt' | translate}}</button>
        <button type="button" class="btn btn-primary btn-md pull-right btnCancelar" style="display: block;float: right;" (click)="onBtnClose()">{{'cancelar' | translate}}</button> 
          
<div class="form-floating mb-3"> 	<p class="protecciodades"><strong>{{'infoBasicaTxt' | translate}}</strong><br>
  <strong>{{'textoPrivacidad1' | translate}}</strong>{{'textoPrivacidad2' | translate}}<strong>{{'textoPrivacidad3' | translate}}</strong>{{'textoPrivacidad4' | translate}}<strong>{{'textoPrivacidad5' | translate}}</strong>{{'textoPrivacidad6' | translate}}<a href="mailto:info@aulaitbook.es"><strong>info@aulaitbook.es</strong></a>{{'textoPrivacidad7' | translate}}<strong>{{'textoPrivacidad8' | translate}}</strong>{{'textoPrivacidad9' | translate}}<a href="mailto:info@aulaitbook.es"><strong>info@aulaitbook.es</strong></a>.</p>
</div>
        </form>
      </div>
  
  </div>
