import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output, Input} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn, Form } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-modificar-clase',
  templateUrl: './modificar-clase.component.html',
  styleUrls: ['./modificar-clase.component.css']
})
export class ModificarClaseComponent implements OnInit {

  @Output() eventModifiClaseCompleted = new EventEmitter<string>();
  @Output() eventClaseCloseCompleted = new EventEmitter();

  @Input() nombreClase = "";
  @Input() alumnos = [];
  @Input() classId = "";

  constructor( private formBuilderMC: FormBuilder,
    private service:LoginService, 
    private router: Router, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
    }

  formEditarClase: FormGroup;
  submitted = false;
 
  ngOnInit(): void {

    this.formEditarClase = this.formBuilderMC.group({
      nameClass: ['',
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)]
    });

  }


  onReset(): void {
    console.log("Reset modificar cklase");
    this.submitted = false;
    this.formEditarClase.reset();
  }

  get mofifyF(): { [key: string]: AbstractControl } {

    // console.log(this.formEditarClase.controls);
    return this.formEditarClase.controls;
  }


  modifyClase(){

    this.submitted = true;
    if (this.formEditarClase.controls.nameClass.invalid) {
      return;
    }

    this.service.modifyClass(this.formEditarClase.controls.nameClass.value, this.classId ).subscribe(response => {
      if(parseInt(response['CODE']) == 1){
        this.eventModifiClaseCompleted.emit(this.classId);
      }else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
}

showBorrar = false;
onCheckboxChange(){
  
  this.showBorrar = (document.getElementById("borrarCheckbox") as HTMLInputElement).checked;
}

deleteClase(){
    this.service.deleteClass(this.classId).subscribe(response => {

      if(parseInt(response['CODE']) == 1){
        this.eventModifiClaseCompleted.emit(null);
      }else{
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
}


closeCreateClase(){
  this.eventClaseCloseCompleted.emit();
}



}
