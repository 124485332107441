import { Component, OnInit, ViewChild, ViewChildren, EventEmitter, Output, Input} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, AsyncValidatorFn, Form } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-modificar-alumno',
  templateUrl: './modificar-alumno.component.html',
  styleUrls: ['./modificar-alumno.component.css']
})
export class ModificarAlumnoComponent implements OnInit {

  constructor( private formBuilderMC: FormBuilder,
    private service:LoginService, 
    private router: Router, 
    public translate: TranslateService) { 
      translate.addLangs(['en-GB', 'es-ES', 'ca-ES', 'va-ES']);
    }

    
    
  showBorrar = false;


  formEditarAlumno: FormGroup;
  submitted = false;

  @Output() eventClaseCloseCompleted = new EventEmitter<boolean>();
  @Input() studentId = "";
  @Input() name = "";
  @Input() surname = "";
  @Input() licennceEnds ="";
  @Input() isValida = false;
  tituloProyecto = "";


  ngOnInit(): void {


    this.formEditarAlumno = this.formBuilderMC.group({
      licenciaNameEditar: ['',
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)],
      NombreAlumnoEditar: ['',
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)],
      ApellidoAlumnoEditar: ['',
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20)],

    });

    this.tituloProyecto =  document.getElementById("tituloProyectoHelp").innerText;

  }


  licenciaValida = true;
  createStudent(){
    this.licenciaValida = true;

    this.service.modifyStudent(this.formEditarAlumno.controls.licenciaNameEditar.value, this.formEditarAlumno.controls.NombreAlumnoEditar.value, this.formEditarAlumno.controls.ApellidoAlumnoEditar.value, this.studentId).subscribe(response => {
      console.log(response);
      if(parseInt(response['CODE']) < 1){
        this.licenciaValida = false;
      }else{
        this.licenciaValida = true;
        this.eventClaseCloseCompleted.emit(true);
      }
    });

  }
  deleteStudent(){

    this.service.deleteStudent(this.studentId).subscribe(response => {
      console.log(response);
      this.eventClaseCloseCompleted.emit(true);
    });
  }

  closeCreateClase(){
    this.eventClaseCloseCompleted.emit(false);
  }

  onCheckboxChange(){
    this.showBorrar = !this.showBorrar;

  }

  onReset(): void {
    this.submitted = false;
    this.formEditarAlumno.reset();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formEditarAlumno.controls;
  }

  proyectoName(){
    
  }

}
