<main class="fondo">
    <app-top-bar></app-top-bar>
    <!-- <app-sub-bar></app-sub-bar> -->

    <!-- <div class="container caja-superior tabsCOntainer"> -->

      <div style="padding-top: 0%;"></div>
      <div class="container">
          <div *ngFor="let proyect of proyects">
            <div class="card" *ngIf="proyect.isAvaliable >= 0">
              <img type="button" class="img-thumbnail" src="../../../assets/selector/{{proyect.imageURL}}" (click)="onAppSelected(proyect)">
              <div class="ribbon red" *ngIf="proyect.isAvaliable==0"><span>{{'ProxTxt' | translate}}</span></div>
              <!--  <div class="ribbon blue" *ngIf="proyect.isAvaliable==1"><span>En breve</span></div>
              <div class="ribbon" *ngIf="proyect.isAvaliable==2"><span>Nuevo</span></div> -->

            </div>
          </div>
      </div>
    <!-- </div> -->
</main>
<app-footer></app-footer>